import React, { state,useState, useEffect } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { useNavigate, useParams } from "react-router-dom";
import logos from '../../../img/Logo-Recovered.png'
import education from '../../../img/icon/puzzle.png';
import healthcare from '../../../img/icon/healthcare.png';
import colorPallet from '../../../img/icon/color-palette.png';
import building from '../../../img/icon/building.png';
import trade from '../../../img/icon/trade.png';
import compliant from '../../../img/icon/compliant.png';
import software from '../../../img/icon/software-engineer.png';
import custemer from '../../../img/icon/customer-service.png';
import vihecles from '../../../img/icon/vehicles.png';
import shield from '../../../img/icon/shield.png';
import hand from '../../../img/icon/hand.png';
import flight from '../../../img/icon/flight.png';

import { useDispatch, useSelector } from 'react-redux';
import { getManufacture, viewManufacture } from '../../../actions/manufactureAction';

import Loading from '../loading';

const Manufacture_detail = () =>{

  const dispatch = useDispatch();

  const id= useParams();

    // use effect
    // useEffect(() => {
    //   dispatch(viewManufacture());
    // }, []);
    
    const {manufacture} = useSelector(
      (state) => state.manufacture
    );

    useEffect(() => {
      dispatch(getManufacture(id));
    }, []);

    console.log("list of manufacture:", manufacture);

   const slides = [
    {
      url: 'https://address-backend.eplusapp.et/images/1687441951-photo_2023-06-22_22-1x6-28.png?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1670&q=40',
    },
    {
      url: 'https://address-backend.eplusapp.et/images/1687437759-product2.png?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80',
    },

    {
      url: 'https://address-backend.eplusapp.et/images/1687445262-R.png?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2253&q=80',
    },
    {
      url: 'https://address-backend.eplusapp.et/images/1687442076-photo_2023-06-22_22-16-28.png?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2672&q=80',
    },
    {
      url: 'https://address-backend.eplusapp.et/images/1687445134-OIP.jpg?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2672&q=80',
    },
    ];
  
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const prevSlide = () => {
      const isFirstSlide = currentIndex === 0;
      const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };
  
    const nextSlide = () => {
      const isLastSlide = currentIndex === slides.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    };
  
    const goToSlide = (slideIndex) => {
      setCurrentIndex(slideIndex);
    };
   

 return(

    <>

      <div className="hidden md:flex h-96 w-full m-auto relative">

         <div className="hidden md:flex w-full md:w-2/12 lg:w-2/12 h-full relative pl-10 pr-10 ">
            <ul className="space-y-1">    
                <li className=' bg-white rounded-lg shadow-inner shadow-blue-950/40 h-5'>
                    <div class="inline-grid grid-cols-2 gap-x-1">
                    <div class=""><img className='h-5 w-5 ml-5' src={education}/></div>
                    <div class=" text-md">ትምህርት</div>
                   </div>
                  </li>
                  <li className=' bg-white rounded-lg shadow-inner shadow-blue-950/40 h-5'>
                    <div class="inline-grid grid-cols-2 gap-x-5">
                    <div class=""><img className='h-5 w-5 ml-5' src={healthcare}/></div>
                    <div class="text-md ">ጤና</div>
                   </div>  
                  </li>
                  <li className=' bg-white rounded-lg shadow-inner shadow-blue-950/40 h-5'>
                    <div class="inline-grid grid-cols-2 gap-x-1">
                    <div class=""><img className='h-5 w-5 ml-5' src={colorPallet}/></div>
                    <div class="text-md ">ዕደጥበብ</div>
                   </div>
                  </li>
                  <li className=' bg-white rounded-lg shadow-inner shadow-blue-950/40 h-5'>
                    <div class="inline-grid grid-cols-2 gap-x-4">
                    <div class=""><img className='h-5 w-5 ml-5' src={building}/></div>
                    <div class=" text-md">ግንባታ</div>
                   </div>  
                  </li>
                  <li className=' bg-white rounded-lg shadow-inner shadow-blue-950/40 h-5'>
                    <div class="inline-grid grid-cols-2 gap-x-4">
                    <div class=""><img className='h-5 w-5 ml-5' src={trade}/></div>
                    <div class="text-md ">ችርቻሮ</div>
                   </div>
                  </li>
                  <li className=' bg-white rounded-lg shadow-inner shadow-blue-950/40 h-5'>
                    <div class="inline-grid grid-cols-2 gap-x-5">
                    <div class=""><img className='h-5 w-5 ml-5' src={compliant}/></div>
                    <div class=" text-md">ሕግ</div>
                   </div>  
                  </li>
                <li className=' bg-white rounded-lg shadow-inner shadow-blue-950/40 h-5'>
                    <div class="inline-grid grid-cols-2 gap-x-1">
                    <div class=""><img className='h-5 w-5 ml-5' src={software}/></div>
                    <div class="text-md ">አስተዳደር</div>
                   </div>  
                  </li>
                <li className=' bg-white rounded-lg shadow-inner shadow-blue-950/40 h-5'>
                    <div class="inline-grid grid-cols-2 gap-x-1">
                    <div class=""><img className='h-5 w-5 ml-5' src={custemer}/></div>
                    <div class="text-md ">አገልግሎት</div>
                   </div>  
                  </li>
                <li className=' bg-white rounded-lg shadow-inner shadow-blue-950/40 h-5'>
                    <div class="inline-grid grid-cols-2 gap-x-5">
                    <div class=""><img className='h-5 w-5 ml-5' src={vihecles}/></div>
                    <div class="text-md ">መጓጓዝ</div>
                   </div>  
                  </li>
                <li className=' bg-white rounded-lg shadow-inner shadow-blue-950/40 h-5'>
                    <div class="inline-grid grid-cols-2 gap-x-5">
                    <div class=""><img className='h-5 w-5 ml-5' src={shield}/></div>
                    <div class="text-md ">ደህንነት</div>
                   </div>  
                  </li>
                <li className=' bg-white rounded-lg shadow-inner shadow-blue-950/40 h-5'>
                    <div class="inline-grid grid-cols-2 gap-x-5">
                    <div class=""><img className='h-5 w-5 ml-5' src={hand}/></div>
                    <div class="text-md ">ምጣኔ</div>
                   </div>  
                  </li>
                <li className=' bg-white rounded-lg shadow-inner shadow-blue-950/40 h-5'>
                    <div class="inline-grid grid-cols-2 gap-x-2">
                    <div class=""><img className='h-5 w-5 ml-5' src={flight}/></div>
                    <div class="text-md ">ቱሪዝም</div>
                   </div>  
                  </li>
       
                <div className='relative block bg-white rounded-lg shadow-lg shadow-blue-950/40 mt-1'>
                 <div className='relative overflow-hidden bg-no-repeat bg-cover relative overflow-hidden bg-no-repeat bg-cover shadow-lg rounded-lg p-2'>
                  <img src={logos}/>
                 </div>
             
                </div>
            </ul>
         </div>

          <div class="hidden md:flex w-full lg:w-7/12 md:w-7/12 h-full mt-2">
             <div className='h-full w-full m-auto relative group rounded-lg shadow-inner shadow-blue-950/40 p-1'>
              {/* {
                (manufacture?.length)>0
                ?(
                  manufacture?.slice(0, 1).map((manufactures,index)=>(
                 <> */}

              <div
                 style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
                 className='w-full h-full rounded-xl bg-center bg-cover duration-500'
               >
              </div>
                 {/* product secfication buttom*/}

           <div className=" m-auto">
              <div className="w-4/12 group-hover:block absolute bottom-0  h-16 left-0 text-black cursor-pointer rounded-tr-xl rounded-bl-xl p-2 opacity-70 duration-300  bg-gray-100 border-2 border-slate-400 shadow-inner shadow-blue-950/40"> 
                <p className="text-lg font-bold">ኢጵላሣጵ<sub className="text-[#0397FF]">231245</sub> </p>
              </div>

            <div className='w-8/12 group-hover:block absolute bottom-0 right-0 text-md rounded-br-xl  opacity-70 duration-300  bg-gray-100 text-black cursor-pointer border-2 border-slate-400 shadow-inner shadow-blue-950/40'>
              <div class="grid grid-cols-2 divide-x-2 divide-slate-400 h-9">

                     <div className="text-xl"> 
                       <p className="text-md">ዋጋ <sup className="text-[#0397FF]">100000</sup> ብር</p>
                     </div>
                     <div className=" grid grid-cols-3 divide-x-2 divide-slate-400">
                     <div className=" text-sm">
                        <button>ክፍት<sup className="text-[#0397FF]">1</sup></button>
                     </div>
                     <div className="text-sm">
                        <button>ጥራት<sup className="text-[#0397FF]">4</sup></button>
                     </div>
                     <div className="inset-y-0 right-0 text-sm">
                        <button>ክፍል</button>
                     </div>
                   
                   </div>
              </div>
            </div>
            </div>
             {/* product secfication buttom*/}
             <div className=' group-hover:block absolute bottom-10 -translate-x-0 right-0 top-0 text-sm p-1 rounded-sm cursor-pointer text-black border-2 rounded-tr-xl rounded-brl-xl border-slate-400 shadow-inner shadow-blue-950/40 opacity-70 duration-300  bg-gray-100'>
              <div className="relative divide-y-2 divide-slate-400">
                    <div className="pt-6">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 ml-2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                        </svg>
                        <button>ውድድ</button>
                     </div>
                     <div className="pt-6">
                     <div className="flex h-6 w-6 items-center justify-center rounded-full ml-1 border-2">ሼ</div>
                        <button>ግርት</button>
                     </div>
                     <div className="pt-6">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 ml-2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                        </svg>

                        <button>እድግ</button>
                     </div>
                     <div className="pt-6">
                       <svg xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-5 h-5 ml-2">
                         <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                        <button>እውቅ</button>
                     </div>

                     {/* <h1>
                     <div className="fixed inset-x-0 bottom-0">

                        <button>እውቅ</button>
                     </div>
                     </h1> */}

              </div>
            </div>

             {/* Left Arrow */}
           <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
             <BsChevronCompactLeft onClick={prevSlide} size={30} />
           </div>
             {/* Right Arrow */}
           <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
             <BsChevronCompactRight onClick={nextSlide} size={30} />
           </div>
                  {/* <div className='flex top-4 justify-center py-2'>
                          {slides.map((slide, slideIndex) => (
                         <div
                           key={slideIndex}
                           onClick={() => goToSlide(slideIndex)}
                           className='text-2xl cursor-pointer'
                         >
                         <RxDotFilled />
                       </div>
                        ))}
                      </div> */}
                  {/* </>
                ))):(null)
              } */}
           </div>
        </div>


               <div class="hidden md:flex w-full lg:w-3/12 md:w-3/12 h-full border-2 border-slate-400 rounded-md shadow-inner shadow-blue-950/40 m-2 pl-2">
                 <p className="p-3">
                 ኢጵላሣጵ አዲስ አሰራር [እይት]፣ [ትኩርና] ዲጂታል የንግድ ተቋም [ምስርት] ነው። ኢጵላሣጵ
                  ለመረጃ አጠቃቀም፣ ደኅንነት፣ ልውውጥና ክምችት(አደልክ) የሚረዱ መጥቀቶችን ወይም 
                  ሙያዊ አገልግሎቶችን [ፍልግ]፣ [ንድፍ]፣ [ፍብርክ]፣ [ሙክር]፣ [ጥግንና] ከመደብ ተግዳሮቶች
                   በላይ ትግብር ነው።  
                   አወንታዊ ተፅኖ ፍጥር፣ ለተቋማት የቴክኖሎጂ መፍትሄዎችን ብልጽግ፤ በሰፊ የፈጠራ አቀራረብ ዲጂታል ጉጉትን
                    ግኝት፣ አገር በቀል ብራንድ ወይም መለዮ ፍጥር ነው።                
                 </p>
               </div>
      </div>
    
    </>
 )
}
export default Manufacture_detail;