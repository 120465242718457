import React, { useEffect, useState } from "react";
import Layout from "../../layout/layout";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { productCategory } from "../../../actions/productAction";
import Loading from "../loading";
import AddressBaseUrl from "../../../utils/BaseUrl";
import { FaStar } from "react-icons/fa";
import { viewProductRate } from "../../../actions/productRateAction";
import { useForm } from "react-hook-form";
import Favorite from "./favorite";
import ProductModel from "./popup-model";
import Loginform from "../../users/login";
import { addToCart } from "../../../actions/cartAction";
import Service from "../eplusappDetail/service";
import { dataProducts } from "../data";

const Category = () => {
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLogin, setShowLogin] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const { Cname } = useParams();
  const { product } = useSelector((state) => state.product);
  useEffect(() => {
    dispatch(productCategory(Cname));
  }, []);

  //navigate to product orders page
  const handleGetOrder = (id) => {
    navigate("/product/orders/" + id);
  };
  // navigate to product like pages
  const handleGetLike = (id) => {
    navigate("/product/like/" + id);
  };
  const { productRate } = useSelector((state) => state.productRate);
  useEffect(() => {
    dispatch(viewProductRate());
  }, []);
  const rateIndex = productRate.length;
  let Total = 0;
  let newData1 = Array.prototype.map.call(productRate, (item) => {
    Total = +Total + +item.rating;
  });
  const totalRating = Total / rateIndex;
  console.log("rate : ", totalRating);
  const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9",
  };
  const styles = {
    stars: {
      display: "flex",
      flexDirection: "row",
    },
  };
  const stars = Array(5).fill(0);

  //statick data start
  const [data, setData] = useState(dataProducts || "");

  useEffect(() => {
    const result = dataProducts?.filter((curData) => {
      return curData.categoryName === Cname;
    });
    setData(result);
  }, [dataProducts]);

  return (
    <Layout>
      <div className="bg-[#E3E6E6]">
        <div className="w-11/12 xl:w-11/12 mx-auto pt-24 md:pt-12 lg:pt-12 pb-8">
          <section className="mb-6 text-gray-800 flex">
            <div class="grid lg:grid-cols-4 xl:gap-5 md:gap-6 xl:gap-x-4">
              {data?.length > 0 ? (
                data?.slice(0, 15).map((products, index) => (
                  <div key={index} className="mb-6 lg:mb-0">
                    <div className="relative group block bg-white rounded-lg overflow-hidden">
                      <div className="flex">
                        <div
                          className="p-2 relative overflow-hidden bg-no-repeat bg-cover relative overflow-hidden bg-no-repeat bg-cover rounded-lg"
                          data-mdb-ripple="true"
                          data-mdb-ripple-color="light"
                        >
                          <img
                            className="w-screen h-52 transition cursor-pointer duration-700"
                            // src={`${AddressBaseUrl}/images/${products.featureImage}`}
                            src={products?.linkImg}
                            alt="product img not found"
                          />
                        </div>
                      </div>

                      <div className="group-hover:block absolute w-full group-hover:opacity-70 opacity-10 duration-700 -translate-x-0  top-[25%]">
                        <div className="flex justify-center">
                          <button
                            className="relative flex p-2 bg-zinc-950 hover:bg-[#0397FF] text-sm opacity-100 text-white cursor-pointer hover:text-black duration-700"
                            onClick={() => dispatch(addToCart(products))}
                          >
                            add to cart
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-4 h-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                              />
                            </svg>
                          </button>
                          <button
                            className="flex p-2 px-4 bg-[#E3E6E6] hover:bg-[#0397FF] text-sm opacity-100 text-black cursor-pointer hover:text-white duration-700"
                            onClick={() => handleGetOrder(products.id)}
                          >
                            pay
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>

                      {/* product secfication buttom*/}
                      <div className="group-hover:block absolute bottom-7 -translate-y-0 right-2 group-hover:top-2 -top-12 text-sm rounded-sm cursor-pointer text-black rounded-tr-xl  opacity-70 transition-[1s] duration-1000">
                        <div className="relative">
                          <div className="pt-1">
                            <button
                              className="group block bg-[#E3E6E6] hover:bg-[#0397FF] p-2 rounded-full hover:rotate-180 duration-1000"
                              onClick={() => handleGetOrder(products.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-5 h-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                />
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className=" group-hover:block absolute bottom-[50%] -translate-x-0 group-hover:right-2 -right-12 text-sm rounded-sm cursor-pointer text-black rounded-tr-xl  opacity-70 duration-700">
                        <div className="relative">
                          <div className="pt-1">
                            {userInfo ? (
                              <Favorite productSLike={products} />
                            ) : (
                              <button
                                className="bg-[#E3E6E6] hover:bg-[#0397FF] duration-700 p-2 rounded-full hover:rotate-45 rotate-duration-1000"
                                onClick={() => {
                                  setShowLogin(true);
                                }}
                                data-te-toggle="tooltip"
                                data-te-placement="top"
                                data-te-ripple-color="light"
                                title="ውድድ"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-5 h-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                                  />
                                </svg>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="p-2 m-auto">
                        <p className="flex text-sm font-bold  mt-4 justify-center">
                          {products.name}
                        </p>
                        <p className="flex mb-2 text-sm justify-center">
                          {products.description.substring(0, 74) + " ..."}
                        </p>
                        <div
                          style={styles.stars}
                          className="m-auto justify-center"
                        >
                          {stars.map((_, index) => {
                            return (
                              <FaStar
                                key={index}
                                size={15}
                                color={
                                  totalRating > index
                                    ? colors.orange
                                    : colors.grey
                                }
                                style={{
                                  marginRight: 5,
                                  cursor: "pointer",
                                }}
                                {...register(`rating`)}
                                values={totalRating}
                              />
                            );
                          })}
                          <h1 className="text-sm">rate</h1>
                        </div>
                        <p className="flex text-lg font-bold justify-center">
                          {products.price} birr
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>
                  <Loading />
                </div>
              )}
            </div>
          </section>
        </div>
        <ProductModel isVisible={showLogin} onClose={() => setShowLogin(false)}>
          <Loginform />
        </ProductModel>
        <div className="pb-6">
          <Service />
        </div>
      </div>
    </Layout>
  );
};
export default Category;
