import { createSlice } from '@reduxjs/toolkit'
import { ProductCategory } from '../actions/productCategory'

// initial state
const initialState = {
  loading: false,
  productCategorys:[],
  productCategory: {},
  error: false,
  success: false,
  message: null,
}

// productCategory slice
const categorySlice = createSlice({
  name: 'productCategory',
  initialState,
  reducers: {},
  extraReducers: {

    // add product orders
    [ProductCategory.pending]: (state) => {
      state.loading = true
      state.error = false
    },
    [ProductCategory.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true 
      state.productCategory = payload
    },
    [ProductCategory.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },

  },

})

// export
export default categorySlice.reducer