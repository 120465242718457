import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/layout";
import { addProductRate, viewProductRate } from "../../../actions/productRateAction";
import { loginUser } from "../../../actions/usersAction";
import { HiOutlineX } from "react-icons/hi";
import AddressBaseUrl from "../../../utils/BaseUrl";
import { viewManufacture, getManufacture } from "../../../actions/manufactureAction";
import product3 from '../../../img/img1.jpg'

import { FaStar } from "react-icons/fa";

const ManufactureRate = () =>{

  const [open, setOpen] = useState(false);

  const dispatch=useDispatch();
  const navigate=useNavigate();
  
    const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
    
    function Logout(){  
      localStorage.clear();
      navigate("/");
    }
        // if(userInfo==null)
        //    {
        //     navigate("/");
        //     }

 const {id}= useParams();

 const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

    // use form
    const {
      handleSubmit: handleLoginSubmit,
      register: registerLogin,
      formState: { errors: loginErrors },
    } = useForm();
  
    // use selector
    const {isLogin, loading, user } = useSelector(
      (state) => state.user
    );

   const { product } = useSelector(
    (state) =>state.product
     );

   const { productRate } = useSelector(
    (state)=>state.productRate
     );

  const userId = productRate.userId  ;

  console.log("product rate is :", productRate.rating && productRate?.rating);
  console.log("user id is :", userId);

  const { manufacture } = useSelector(
    (state) => state.manufacture
  );
//  console.log("all rates :",productRate);

 useEffect(()=>{
     dispatch(viewManufacture());
      },[]);

   useEffect(()=>{
     dispatch(getManufacture(id));
      },[]);

      useEffect(()=>{
        dispatch(viewProductRate(id));
         },[]);     

  const productImg = product?.featureImage;
  const productDis = product?.description;
  const productPreviewId = product?.id;

  console.log("product Image : ",product.galleryImage && product.galleryImage[0]?.imageName);
  
  // const onReset = async () => {
  //   const result = await dispatch(getProduct(id));
  //   reset(result.payload);
  // };

  // use effect
  // useEffect(() => {
  //   onReset();
  // }, []);       

  const onSubmit = (data) => {
    console.log("data is submited");
  
    // pass the data form data
    const formData = new FormData();
    Object?.entries(data).map((entry) => {
      const [key, value] = entry;
      
      formData.append(key, value);
    });
    dispatch(addProductRate(data));
  };

  // login
  const handleLogin = (data) => {
    dispatch(loginUser(data));
  };

  const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9"
    };

const styles = {
  stars: {
    display: "flex",
    flexDirection: "row",
    }
  };

const [currentValue, setCurrentValue] = useState(0);
const [hoverValue, setHoverValue] = useState(undefined);
const stars = Array(5).fill(0)

  const handleClick = value => {
    setCurrentValue(value)
    }
 console.log("rating is :", currentValue);

  const handleMouseOver = newHoverValue => {
   setHoverValue(newHoverValue)
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined)
   }
    return(
        
    <Layout>
      <div className="pb-4 pt-24">
      <form onSubmit={handleSubmit(onSubmit)}>
          <div className="md:flex w-11/12 m-auto relative">

           <div className=" md:flex w-full  h-full relative px-6 md:px-24 lg:px-24">
               <div className="mb-6 lg:mb-0">
                  <div className="md:flex relative block">
                    <div className="flex">
                      <div
                        className="relative overflow-hidden bg-no-repeat bg-cover relative overflow-hidden bg-no-repeat bg-cover shadow-lg rounded-lg"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="light"
                      >
                        <img
                          className="w-screen h-72 md:h-80 lg:h-80 transition cursor-pointer duration-700"
                        //   src={`${AddressBaseUrl}/images/${productImg}`} 
                          src= {product3}
                          alt="news img"
                        />
                      </div>
                    </div>
                <div className="mx-2 md:mx-6 lg:mx-8">
                   <div className="relative flex md:col-span-2 md:my-3">
                    <h1>{productDis}</h1>
                  </div>
                  <div className="relative flex md:col-span-1 md:my-1 border border-2">
                     <input class="appearance-none block w-full text-gray-700 py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                       id="titele" 
                       type="text"
                       placeholder="ደረጃ ከመስጠትዎ በፊት ሃሳብ አስተያየትዎን ያጋሩን"
                        {...register("review", {
                       required: true,
                         })}
                     />
                  </div>
                  <div className="hidden relative flex md:col-span-1 md:my-1 border border-2">
                     <input class="appearance-none block w-full text-gray-700 py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                       id="titele" 
                       value={productPreviewId}
                       placeholder="productId"
                        {...register("productId", {
                       required: true,
                         })}
                     />
                  </div>

                  <div className="relative flex md:col-span-2 md:my-3">
                   <label className="left-2 px-1 bg-white text-gray-500 text-sm " >
                     ጥራት: 
                   </label>
      <div style={styles.stars}>
        {stars.map((_, index) => {
          return (
            <FaStar
              key={index}
              size={24}
              onClick={() => handleClick(index + 1)}
              onMouseOver={() => handleMouseOver(index + 1)}
              onMouseLeave={handleMouseLeave}
              color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
              style={{
                marginRight: 10,
                cursor: "pointer"
              }}
              {...register(`rating`)}
              values={currentValue}
            />
          )
        })}
      </div>
    </div>

         { userInfo ? (
          <button
             type="submit"
              className="bg-[#0397FF] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              ያረጋግጡ
          </button>
          ) :(
          <button
              onClick={() => setOpen(true)}
              className="bg-[#0397FF] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              type="submit"
              // onClick={Logout}
             >
              ያረጋግጡ
          </button>
          )}
       </div>
       </div>
      </div>
     </div>

    {/* <div className=" md:flex w-full h-full relative px-6 md:px-12 lg:px-12">
             <section className=" mb-12 text-gray-800 text-center ">
             {
              (productRate?.length)>0
              ?(
                 productRate?.map((productRates, index) =>(
                  <div key={index} className="mb-4 lg:mb-0 pt-4">
                    <h1>{productRates.review}</h1>
                    <h1>{productRates.rating}</h1>
                  </div>
                ))
              ):(<div><Loading /></div>)
            }
          </section>
         </div> */}

          </div>
        </form>

        {open && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none -mt-6 border border-grey-100">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-[#E4E4E4] outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex justify-end p-1">
                    <button
                      onClick={() => setOpen(false)}
                      type="button"
                      className="text-red-600 bg-transparent hover:bg-gray-200 rounded-lg text-lg p-1 ml-auto inline-flex items-center"
                      data-modal-toggle="log-in-model"
                    >
                      <HiOutlineX className="w-6 h-6" />
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative px-4 pb-4">
                    <div className="p-6 pt-0 ">
                    <form onSubmit={handleLoginSubmit(handleLogin)}>
                        <div className="font-display flex flex-col justify-center items-center">
                          <h2 className="my-2  font-bold text-secondary text-4xl text-gray-700 text-center">
                            ይህ ኢጵላሣጵ የመገበያያ አውድ ነው
                          </h2>
                        <div className="inline-grid grid-cols-2 gap-x-4">
                          <div>
                            <img src={product3}  />
                          </div>
                          <div className="">
                          <div className="mb-4 w-full">
                            <span className="block text-lg font-display text-inputColor">
                              ኢሜል
                            </span>
                            <input
                              type="text"
                              {...registerLogin("email", {
                                required: true,
                                pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                              })}
                              className="form-control block w-full px-4 py-2 text-base text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none"
                            />
                            {loginErrors.email && (
                              <p className="text-red-500">
                                ያስገቡት ኢሜል ትክክል መሆኑን ያረጋግጡ
                              </p>
                            )}
                          </div>
                          <div className="mb-2 w-full">
                            <span className="block text-lg font-display text-inputColor">
                              የይለፍ ቃል
                            </span>
                            <input
                              type="password"
                              {...registerLogin("password", {
                                required: true,
                                maxLength: 15,
                              })}
                              className="form-control block w-full px-4 py-2 text-base text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                            />
                            {loginErrors.password && (
                              <p className="text-red-500">
                                ያስገቡት የይለፍ ቃል ትክክል መሆኑን ያረጋግጡ
                              </p>
                            )}
                          </div>

                {loading ? (
                <button
                  disabled
                  className="inline-block px-2 xl:py-2 py-2 mt-6 bg-primary text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-primary hover:shadow-lg focus:bg-primary focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary active:shadow-lg transition duration-150 ease-in-out w-full"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="inline w-4 h-4 mr-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  loading...
                </button>
              ) : (
                <button
                  type="submit"
                  className="inline-block px-2 py-1 mt-6 bg-primary text-white font-medium text-lg leading-snug uppercase rounded shadow-md hover:bg-primary hover:shadow-lg focus:bg-primary focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary active:shadow-lg transition duration-150 ease-in-out w-full"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                  // onClick ={ () => handleGetOrder(products.id)}
                >
                  ግብት
                </button>
              )}
                         </div>

                       </div>
                          
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
    )
}
export default ManufactureRate;