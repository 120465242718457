// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError, handleSuccess } from "../utils/toast";

export const addProductRate = createAsyncThunk(
  "productRate",
  async (reviewData, thunkAPI) => {
    try {
      const userInfo = localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : null;
      //  console.log("current rate", reviewData);
      let URL = `http://localhost:11217/ecommerceapi/ratereview/create`;
      // let response = await createContact(message);
      let response = await axios.post(URL, reviewData, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      //  console.log("rate response information is :", response);
      if (response.status === 201) {
        handleSuccess("ስለ ሰጡን አስተያየት ከልብ እናመሰግናለን !");
        // console.log("rate response information is 1:", response);
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      handleError("loading...");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const viewProductRate = createAsyncThunk(
  "productRate",
  async (id, thunkAPI) => {
    try {
      let URL = `http://localhost:11217/ecommerceapi/ratereview/all/${id}`;
      // let response = await createContact(message);
      let response = await axios.get(URL);
      // console.log("productRate review", response);
      if (response.status === 200) {
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      handleError("loading...");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
