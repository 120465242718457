// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import AddressBaseUrl from "../utils/BaseUrl";
import { handleError } from "../utils/toast";

export const ProductCategory = createAsyncThunk(
    "productCategory", 
    async (thunkAPI) => {
      try {
  
        let URL =`${AddressBaseUrl}/addressapi/category/all`;
  
        // let response = await createContact(message);
        let response = await axios.get(URL);
  
        console.log("all cat : ",response);
  
        if (response.status === 200) {
          return response.data;
        } else {
          handleError(response.message);
          return thunkAPI.rejectWithValue(response.data);
        }
      } catch (e) {
        console.log("Error", e.response.data);
        handleError("loading...");
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );