// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { handleError } from "../utils/toast";

export const ProductOrder = createAsyncThunk(
  "productOrder",
  async (orderData, thunkAPI) => {
    try {
      console.log("send order product Info", orderData);
      let URL = `http://localhost:11217/ecommerceapi/orders/create`;

      // let response = await createContact(message);
      let response = await axios.post(URL, orderData);

      console.log("response", response);

      if (response.status === 200) {
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      handleError("loading...");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
