// add to carts
export const addToCart = (data) => async (dispatch, getState) => {
    dispatch({
      type: "addToCart",
      payload: data,
    });

    localStorage.setItem("cartsItems", JSON.stringify(getState().carts.carts));
    // console.log("product data is is :", data);
    return data;
  };
  // remove from carts
  export const removeFromCarts = (data) => async (dispatch, getState) => {
    dispatch({
      type: "removeFromCarts",
      payload: data.id,
    });
    localStorage.setItem("cartsItems", JSON.stringify(getState().carts.carts));
    return data;
  };

  export const increaseItemQuantity = (data) => async (dispatch, getState) => {
    dispatch({
      type: "increaseItemQuantity",
      payload: data.id,
    });
    localStorage.setItem("cartsItems", JSON.stringify(getState().carts.carts));
    return data;
  };
  export const decreaseItemQuantity = (data) => async (dispatch, getState) => {
    dispatch({
      type: "decreaseItemQuantity",
      payload: data.id,
    });
    localStorage.setItem("cartsItems", JSON.stringify(getState().carts.carts));
    return data;
  };

  export const getCartTotal = (data) => async (dispatch, getState) => {
    dispatch({
      type: "getCartTotal",
      payload: data,
    });
    localStorage.setItem("cartsItems", JSON.stringify(getState().carts.carts));
    return data;
  };