import React, { useEffect, useState } from "react";
import Layout from "../../layout/layout";
import Eplusapp4cards from "../eplusappDetail/eplusapp4cards";
import Topsellsproduct from "./topsellsproduct";
import Product_detail from "./product_detail";
import Allproductcategory from "./allproductcategory";
import BackTotop from "../../navigation/backToTop";
import { viewProducts } from "../../../actions/productAction";
import { getProduct } from "../../../actions/searchProductAction";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../loading";
import AddressBaseUrl from "../../../utils/BaseUrl";
import { NavLink, useNavigate } from "react-router-dom";
import { HiOutlineX } from "react-icons/hi";
import { useForm } from "react-hook-form";
import { addToCart } from "../../../actions/cartAction";
import { FaStar } from "react-icons/fa";
import { viewProductRate } from "../../../actions/productRateAction";
import Service from "../eplusappDetail/service";
import ProductModel from "./popup-model";
import Product_rate from "./product_rate";
import Loginform from "../../users/login";
import Favorite from "./favorite";
import defualtImg from "../../../img/logobg1.png";
import { getProductDetail } from "../../../actions/productDetailAction";
import { dataProducts } from "../data";

const Product = ({ productDetail2 }) => {
  const [share, setShare] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  useEffect(() => {
    dispatch(viewProducts());
  }, []);
  const { loading, product } = useSelector((state) => state.product);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const [productData, setProductData] = useState("");
  const handeleToDetail = (id) => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    dispatch(getProductDetail(id));
    setProductData(id);
  };
  //navigate to product orders page
  const productId = "";
  const handleGetOrder = (id) => {
    navigate("/product/orders/" + id);
  };
  //navigate to add product rating
  // const handleGetRate = (id) => {
  //   if (id !== null) {
  //    dispatch(getProduct(id));
  //     dispatch(viewProductRate(id));
  //     setShowModal(true);
  //   }
  // };
  const handleGetRate = (id) => {
    if (id !== null) {
      localStorage.setItem("productRate", JSON.stringify(id));
      setShowModal(true);
    }
  };
  const { productRate } = useSelector((state) => state.productRate);
  useEffect(() => {
    dispatch(viewProductRate());
  }, []);

  const rateIndex = productRate.length;
  let Total = 0;
  let newData1 = Array.prototype.map.call(productRate, (item) => {
    Total = +Total + +item.rating;
  });
  const totalRating = Total / rateIndex;
  const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9",
  };

  const styles = {
    stars: {
      display: "flex",
      flexDirection: "row",
    },
  };
  const stars = Array(5).fill(0);

  const addToCartHandler = (data) => {
    const newData = { ...data, quantity: 1 };
    dispatch(addToCart(newData));
  };

  return (
    <Layout>
      <div className="bg-[#E3E6E6]">
        <div className="md:pt-0 lg:pt-0 pt-12">
          <Product_detail productDetail2={productData} />
        </div>
        <div>
          <BackTotop />
        </div>
        <div className="pt-10">
          {" "}
          <Topsellsproduct />{" "}
        </div>
        <div className="w-11/12 xl:w-11/12 mx-auto">
          <section className="mb-12 text-gray-800 text-center ">
            <div class="grid lg:grid-cols-4 xl:gap-10 md:gap-6 xl:gap-x-4">
              {dataProducts?.length > 0 ? (
                dataProducts?.slice(0, 8).map((products, index) => (
                  <div key={index.id} className="mb-6 lg:mb-0">
                    <div class="relative group block overflow-hidden bg-white rounded-lg shadow-inner shadow-blue-950/40 p-1">
                      <div className="flex pb-2">
                        <div
                          className="p-2 relative overflow-hidden bg-no-repeat bg-cover rounded-lg"
                          data-mdb-ripple="true"
                          data-mdb-ripple-color="light"
                        >
                          <img
                            className="w-screen h-52 transition cursor-pointer duration-700"
                            // src={`${AddressBaseUrl}/images/${products.featureImage}`}
                            src={products?.linkImg}
                            alt="product img not found"
                            // onClick={() => handeleToDetail(products?.id)}
                            onClick={() => handleGetOrder(products.id)}
                          />
                        </div>
                      </div>
                      <div className="relative mt-0 bg-[#E3E6E6]  transition-all before:absolute before:-mt-[44px] before:transition-all group-hover:-mt-[44px] before:group-hover:-mt-[44px]  duration-1000">
                        <p className="text-sm mt-8 font-bold">
                          {products?.name}
                        </p>
                        <div
                          style={styles.stars}
                          className="m-auto justify-center"
                        >
                          {stars.map((_, index) => {
                            return (
                              <FaStar
                                key={index}
                                size={15}
                                color={2 > index ? colors.orange : colors.grey}
                                style={{
                                  marginRight: 5,
                                  cursor: "pointer",
                                }}
                                {...register(`rating`)}
                                values={2}
                              />
                            );
                          })}
                          <h1 className="text-sm">rate</h1>
                        </div>
                        <p className="mb-2 text-sm ">
                          {products.description.substring(0, 30) + " ..."}
                        </p>
                        <p className="text-lg font-bold ">
                          {products.price} birr
                        </p>
                        <div className="m-auto w-full pb-2 transition-[1s] duration-1000 -bottom-12 relative  group-hover:bottom-0">
                          <div className="flex justify-center">
                            <button
                              className="relative flex p-2 bg-zinc-950 hover:bg-[#0397FF] text-sm text-white cursor-pointer hover:text-black duration-700"
                              onClick={() => addToCartHandler(products)}
                            >
                              add to cart
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-4 h-4"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                                />
                              </svg>
                            </button>
                            <button
                              className="flex p-2 px-4 bg-[#E3E6E6] hover:bg-[#0397FF] text-sm text-black cursor-pointer hover:text-white duration-700"
                              onClick={() => handleGetOrder(products.id)}
                            >
                              pay
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-5 h-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="absolute bottom-7 transition-[1s] -translate-x-0 right-2 -top-40 group-hover:top-2 text-sm rounded-sm cursor-pointer text-black rounded-tr-xl  opacity-70 duration-1000">
                        <div className="relative">
                          <div className="pt-1">
                            {userInfo ? (
                              <Favorite productSLike={products} />
                            ) : (
                              <button
                                className="bg-[#E3E6E6] hover:bg-[#0397FF] duration-700 p-2 rounded-full hover:rotate-45 rotate-duration-1000"
                                onClick={() => {
                                  setShowLogin(true);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-5 h-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                                  />
                                </svg>
                              </button>
                            )}
                          </div>
                          <div className="pt-1">
                            <a
                              className="bg-[#E3E6E6] hover:bg-[#0397FF] duration-700 p-2 rounded-full flex"
                              href={`https://telegram.me/share/url?url=https://test.eplusapp.et/product/orders/${products.id}&text=Eplusapp_Best_Products`}
                            >
                              <div className=" h-5 w-5 items-center justify-center rounded-full">
                                ሼ
                              </div>
                            </a>
                          </div>
                          <div className="pt-1">
                            <button
                              className="bg-[#E3E6E6] hover:bg-[#0397FF] duration-700 p-2 rounded-full"
                              onClick={() => handleGetRate(products.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="black"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-5 h-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                />
                              </svg>
                            </button>
                          </div>
                          <div className="pt-1">
                            <button className="bg-[#E3E6E6] hover:bg-[#0397FF] duration-700 p-2 rounded-full">
                              <NavLink to="https://address.eplusapp.et/main">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-5 h-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
                                  />
                                </svg>
                              </NavLink>
                            </button>
                          </div>
                        </div>
                      </div>
                      {share && productId.length !== null && (
                        <>
                          <div className=" justify-center items-center flex fixed block inset-0 z-50 outline-none focus:outline-none mt-0 left-0 border border-grey-100 ">
                            <div className="relative w-auto my-6 mx-auto bg-[#E4E4E4]">
                              <div className="flex justify-end p-1">
                                <button
                                  onClick={() => setShare(false)}
                                  type="button"
                                  className="text-red-600 bg-transparent hover:bg-gray-200 rounded-lg text-lg p-1 ml-auto inline-flex items-center"
                                  data-modal-toggle="log-in-model"
                                >
                                  <HiOutlineX className="w-6 h-6" />
                                </button>
                              </div>
                              <div className="p-6 flex">
                                <div className="p-2">
                                  <img src={defualtImg} className="h-48 w-48" />
                                </div>
                                <div className="p-1">
                                  <h1>
                                    {products.description.substring(0, 60) +
                                      " ..."}
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div>
                  <Loading />
                </div>
              )}
            </div>
          </section>
        </div>

        <ProductModel isVisible={showModal} onClose={() => setShowModal(false)}>
          <Product_rate />
        </ProductModel>

        <ProductModel isVisible={showLogin} onClose={() => setShowLogin(false)}>
          <Loginform />
        </ProductModel>

        <div className="mt-6 mb-1 pb-4">
          <Eplusapp4cards />
        </div>
        <div className="md:mt-6 lg:mt-6 mt-2 mb-2">
          <Allproductcategory />
        </div>
        <div className="pb-6">
          <Service />
        </div>
      </div>
    </Layout>
  );
};
export default Product;
