import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddressBaseUrl from "../../../utils/BaseUrl";
import { AiFillDelete } from "react-icons/ai";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import eplusLogo from "../../../img/img2.jpg";
import { HiOutlineX } from "react-icons/hi";
import {
  getCartTotal,
  removeFromCarts,
  decreaseItemQuantity,
  increaseItemQuantity,
} from "../../../actions/cartAction";

const Popup_catrs = ({ isVisible, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cartOpen, setCartOpen] = useState(false);
  const { carts, totalQuantity, totalPrice } = useSelector(
    (state) => state.carts
  );

  const { product } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(getCartTotal());
  }, [carts]);

  if (!isVisible) return null;
  const handleClose = (e) => {
    if (e.target.id === "productCart") onClose();
  };
  return (
    <>
      <div
        className="justify-start items-start overflow-y-hidden overflow-x-auto fixed inset-0 z-50 outline-none focus:outline-none -mt-0 flex"
        id="productRate"
        onClick={handleClose}
      >
        <div className="relative w-auto my-10 mr-0 mx-auto max-w-3xl">
          <div className="shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-screen">
            <div className="ml-0 z-20 -mb-8">
              <button
                onClick={() => onClose()}
                type="button"
                className="text-red-600 bg-transparent hover:bg-gray-200 rounded-lg text-lg p-1 ml-auto inline-flex items-center"
                data-modal-toggle="log-in-model"
              >
                <HiOutlineX className="w-6 h-6" />
              </button>
            </div>
            <div className="py-5">
              <div className="md:flex justify-content-center my-4 max-h-screen overflow-y-auto">
                <div className="mx-6 ">
                  {carts?.map((data) => (
                    <div className=" ">
                      <div className="flex ">
                        <div className="space-x-2 col-md-12 mb-2 mb-lg-0">
                          <div
                            className="hover-overlay hover-zoom rounded"
                            data-mdb-ripple-color="light"
                          >
                            <img
                              //  src={`${AddressBaseUrl}/images/${data.featureImage}`}
                              src={data?.linkImg}
                              className="w-20 h-20"
                              alt="defualt product images"
                            />
                          </div>
                        </div>
                        <div className="mx-4 mb-lg-0">
                          <p className="text-black">
                            <strong>{data?.name?.substring(0, 12)}</strong>
                          </p>
                          <p className="text-center text-md-center pt-4 text-black">
                            <strong>{data.price}</strong>
                          </p>
                        </div>
                        <div className="flex mb-2 mb-lg-0 md:ml-12 lg:ml-12 ml-6">
                          <div className="mb-4">
                            <button
                              className="btn btn-primary px-3 me-2 bg-[#E3E6E6]"
                              onClick={() =>
                                dispatch(decreaseItemQuantity(data))
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="black"
                                class="w-5 h-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M18 12H6"
                                />
                              </svg>
                            </button>
                            <div className="p-1">
                              <input
                                id="form1"
                                min="0"
                                name="quantity"
                                value={data.quantity}
                                type="number"
                                className="w-8 text-black ml-3"
                                onChange={() => null}
                              />
                            </div>
                            <button
                              className="btn btn-primary px-3 me-2 bg-[#E3E6E6]"
                              onClick={() =>
                                dispatch(increaseItemQuantity(data))
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="black"
                                class="w-5 h-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 6v12m6-6H6"
                                />
                              </svg>
                            </button>
                          </div>
                          <button
                            type="button"
                            className="text-sm right-0 ml-5 mb-5"
                            title="Remove product"
                            onClick={() => dispatch(removeFromCarts(data))}
                          >
                            <AiFillDelete className=" h-6 w-6 text-black" />
                          </button>
                        </div>
                      </div>
                      <hr class="w-full h-0.5 mx-auto bg-gray-300 border-0 rounded md:my-4 dark:bg-gray-700" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="absolute bottom-10 bg-[#0397FF] w-full">
              <NavLink
                to="/cart"
                className=" text-lg text-block text-center rounded-lg text-white mx-24 bottom-0 "
              >
                ክፍያ ይፈፅሙ
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Popup_catrs;
