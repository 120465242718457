import React, { useEffect, useState } from "react";
import { addPayment } from "../../../actions/paymentAction";
import { useDispatch, useSelector } from "react-redux";
import ProductModel from "../products/popup-model";
import Loginform from "../../users/login";

const Pay = ({ amount }) => {
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  const [showLogin, setShowLogin] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch();

  // console.log("Payment Detail Data : ", amount);
  const onSubmit = (data) => {
    const newData = { ...data, amount: data };
    //console.log("passed Data :", newData);
    dispatch(addPayment(newData));
  };
  const { payment, success } = useSelector((state) => state.payment);
  //console.log("paymentDetail : ", payment.callback_url);
  if (success == true) {
    window.location.replace(`${payment.callback_url}`);
  }
  return (
    <>
      {userInfo ? (
        <button
          type="submit"
          onClick={() => onSubmit(amount)}
          className="bg-[#0397FF] text-lg text-block px-6 rounded-lg text-white"
        >
          Pay
        </button>
      ) : (
        <button
          type="submit"
          onClick={() => setShowLogin(true)}
          className="bg-[#0397FF] text-lg text-block px-6 rounded-lg text-white"
        >
          Pay
        </button>
      )}
      <ProductModel isVisible={showLogin} onClose={() => setShowLogin(false)}>
        <Loginform />
      </ProductModel>
    </>
  );
};
export default Pay;
