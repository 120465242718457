import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  carts: localStorage.getItem("cartsItems")
    ? JSON.parse(localStorage.getItem("cartsItems"))
    : [],
    totalQuantity: 0,
    totalPrice: 0,
};

export const cartReducer = createReducer(initialState, {

    addToCart: (state, action) => {
      let find = state.carts.findIndex((item) => item.id === action.payload.id);
      if (find >= 0) {
        state.carts[find].quantity+= 1;
      } else {
        state.carts.push(action.payload);
      }
    },
    increaseItemQuantity: (state, action) => {
      state.carts = state.carts.map((item) => {
        // console.log("total items : ", item)
        if (item.id === action.payload) {
          return { ...item, quantity: item.quantity+ 1 };
        }
        // console.log("cart item is : ", item);
        return item;
      });   
    },
    decreaseItemQuantity: (state, action) => {
      state.carts = state.carts.map((item) => {
        if (item.id === action.payload) {
          if(item.quantity < 2)
          {
            return { ...item, quantity: item.quantity };
          }else{
            return { ...item, quantity: item.quantity- 1 };
          }
        }
        return item;
      });
    },
    removeFromCarts: (state, action) => {
      // console.log("remove data is this item:")
      state.carts = state.carts.filter((item) => item.id !== action.payload);
    },
    getCartTotal: (state) => {
      let { totalQuantity, totalPrice } = state.carts.reduce(
        (cartTotal, cartItem) => {
          // console.log("carttotal", cartTotal);
          // console.log("cartitem", cartItem);
          const { price, quantity } = cartItem;
          // console.log(price, quantity);
          const itemTotal = price * quantity;
          cartTotal.totalPrice += itemTotal;
          cartTotal.totalQuantity += quantity;
          return cartTotal;
        },
        {
          totalPrice: 0,
          totalQuantity: 0,
        }
      );
      state.totalPrice = parseInt(totalPrice.toFixed(2));
      state.totalQuantity = totalQuantity;
    },
});