import { createSlice } from "@reduxjs/toolkit";
import { getProduct } from "../actions/searchProductAction";

// initial state
const initialState = {
  loading: false,
  singleProduct: {},
  singleProduct: [],
  error: false,
  success: false,
  message: null,
};

// products slice
const singleProductSlice = createSlice({
  name: "singleProduct",
  initialState,
  reducers: {},
  extraReducers: {
    // get single products
    [getProduct.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getProduct.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.singleProduct = payload;
      //console.log("singleProduct : ", payload);
    },
    [getProduct.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

// export
export default singleProductSlice.reducer;
