// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError, handleSuccess } from "../utils/toast";

export const addProductLike = createAsyncThunk(
  "productLike",
  async (likeData, thunkAPI) => {
    try {
      const userInfo = localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : null;
      //console.log("post like data : ", likeData);
      let URL = `http://localhost:11217/ecommerceapi/favorites/create`;
      // let response = await createContact(message);
      let response = await axios.post(URL, likeData, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      if (response.status === 201) {
        handleSuccess("thanks");
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      handleError("loading...");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const viewProductLike = createAsyncThunk(
  "productLike",
  async (productId, thunkAPI) => {
    try {
      // console.log("view like product data ", productId);
      let URL = `http://localhost:11217/ecommerceapi/favorites/all/${productId}`;
      // let response = await createContact(message);
      let response = await axios.get(URL);
      //console.log("view like product data ", response);
      if (response.status === 200) {
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      handleError("loading...");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
