import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { addProductRate } from "../../../actions/productRateAction";
import AddressBaseUrl from "../../../utils/BaseUrl";
import defualtImg from "../../../img/logobg1.png";
import { FaStar } from "react-icons/fa";
import ProductModel from "./popup-model";
import Loginform from "../../users/login";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { dataProducts } from "../data";

const ProductRate = (props) => {
  const [showLogin, setShowLogin] = useState(false);
  const dispatch = useDispatch();
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const singlePd = localStorage.getItem("productRate")
    ? JSON.parse(localStorage.getItem("productRate"))
    : null;
  const { singleProduct } = useSelector((state) => state?.singleProduct);
  const product = dataProducts.find(
    (product) => product.id === parseInt(singlePd)
  );
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const { productRate, success } = useSelector((state) => state.productRate);
  const rateIndex = productRate.length;
  let Total = 0;
  let newData1 = Array.prototype.map.call(productRate, (item) => {
    Total = +Total + +item?.rating;
  });
  const totalRating = Total / rateIndex;
  const [currentValue, setCurrentValue] = useState(totalRating || 3);
  const [hoverValue, setHoverValue] = useState(undefined);
  const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9",
  };
  const styles = {
    stars: {
      display: "flex",
      flexDirection: "row",
    },
  };
  const stars = Array(5).fill(0);
  const handleClick = (value) => {
    setCurrentValue(value);
  };
  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };
  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };
  const onSubmitRate = (data) => {
    // console.log("product rating data :", data);
    const formData = new FormData();
    Object?.entries(data).map((entry) => {
      const [key, value] = entry;
      formData.append(key, value);
    });
    const newData = {
      ...data,
      rating: currentValue,
      productId: singleProduct?.id,
    };
    //console.log("send product rate :", newData);
    dispatch(addProductRate(newData));
  };
  // if (success == true) {
  //   window.location.reload();
  // }
  return (
    <>
      <div className="pb-4 pt-6">
        <div className="md:flex relative block">
          <div className="flex">
            <div
              className=" relative overflow-hidden bg-no-repeat bg-cover shadow-lg rounded-lg"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
            >
              <img
                className="w-screen h-72 md:h-80 lg:h-80 transition cursor-pointer duration-700"
                // src={`${AddressBaseUrl}/images/${singleProduct?.featureImage}`}
                src={product?.linkImg}
              />
            </div>
          </div>
          <div className="mx-2 md:mx-6 lg:mx-8">
            <div className="relative flex md:col-span-2 md:my-3">
              <h1>{product?.description}</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmitRate)}>
              <div className="relative flex md:col-span-1 md:my-1 border border-2">
                <input
                  class="appearance-none block w-full text-gray-700 py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="titele"
                  type="text"
                  placeholder="describe your review"
                  {...register("review")}
                />
              </div>
              <div className="relative md:col-span-2 md:my-3">
                <div style={styles.stars}>
                  {stars.map((_, index) => {
                    return (
                      <>
                        <FaStar
                          key={index}
                          size={24}
                          onClick={() => handleClick(index + 1)}
                          onMouseOver={() => handleMouseOver(index + 1)}
                          onMouseLeave={handleMouseLeave}
                          color={
                            (hoverValue || currentValue) > index
                              ? colors.orange
                              : colors.grey
                          }
                          style={{
                            marginRight: 10,
                            cursor: "pointer",
                          }}
                          value={currentValue}
                          // {...register("rating")}
                        />
                      </>
                    );
                  })}
                </div>
                <label className="left-2 px-1 bg-white text-gray-500 text-sm ">
                  add your review
                </label>
              </div>
              {userInfo ? (
                <button
                  type="submit"
                  className="bg-[#0397FF] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  // onClick={handeleToast}
                >
                  submit
                </button>
              ) : (
                <button
                  onClick={() => setShowLogin(true)}
                  className="bg-[#0397FF] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  submit
                </button>
              )}
            </form>
          </div>
        </div>
        <ProductModel isVisible={showLogin} onClose={() => setShowLogin(false)}>
          <Loginform />
        </ProductModel>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default ProductRate;
