import { React, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import classNames from "classnames";
const Invoicer = () => {
  const [isSelected, setIsSelected] = useState(false);
  const LetterRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => LetterRef.current,
    documentTitle: "current-letter",
    onAfterPrint: () => alert("print success"),
  });
  return (
    <>
      <button class="relative -top-4 bg-gray-800 py-2.5 px-5 font-medium uppercase text-white transition-all before:absolute before:top-1  before:h-full before:w-full before:transition-all hover:top-0 before:hover:top-0  duration-700">
        button Three
      </button>
      <button class="relative  bg-transparent py-2.5 px-5 font-medium uppercase text-gray-800 transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-gray-800 before:transition-transform before:duration-300 before:content-[''] hover:text-white before:hover:scale-x-100">
        button one
      </button>
      <main
        className="flex xl:grid grid-cols-2 gap-32 xl:items-start scale-75"
        style={{
          maxWidth: "1920px",
        }}
      >
        {/* Invoice Preview */}
        <div className="bg-white p-5 rounded-2xl border-4 border-blue-200">
          <button
            className="bg-blue-500 ml-5 text-white font-bold py-2 px-8 rounded hover:bg-blue-600 hover:text-white transition-all duration-150 hover:ring-4 hover:ring-blue-400"
            onClick={handlePrint}
          >
            Print / Download
          </button>

          <div ref={LetterRef} className="p-5">
            <header className="flex flex-col items-center justify-center mb-5 xl:flex-row xl:justify-between">
              <div>
                <h1 className="font-bold uppercase tracking-wide text-4xl mb-3">
                  Invoicer
                </h1>
              </div>
            </header>

            <section className="flex flex-col items-end justify-end">
              <h2 className="font-bold text-3xl uppercase mb-1">menberu</h2>
              <p>bahir dar</p>
            </section>

            <section className="mt-10">
              <h2 className="text-2xl uppercase font-bold mb-1">metadel</h2>
              <p>adiss abeba</p>
            </section>

            <article className="mt-10 mb-14 flex items-end justify-end">
              <ul>
                <li className="p-1 ">
                  <span className="font-bold">Invoicer number:</span> 12123
                </li>
                <li className="p-1 bg-gray-100">
                  <span className="font-bold">Invoice date:</span> 10/6/2023
                </li>
                <li className="p-1 ">
                  <span className="font-bold">Due date:</span> 3/2/5
                </li>
              </ul>
            </article>

            <table width="100%" className="mb-10">
              <thead>
                <tr className="bg-gray-100 p-1">
                  <td className="font-bold">Description</td>
                  <td className="font-bold">Quantity</td>
                  <td className="font-bold">Price</td>
                  <td className="font-bold">Amount</td>
                </tr>
              </thead>
              {/* {list.map(({ id, description, quantity, price, amount }) => (
                <React.Fragment key={id}> */}
              <tbody>
                <tr className="h-10">
                  <td>nice product</td>
                  <td>5</td>
                  <td>56.3</td>
                  <td>278.6</td>
                </tr>
              </tbody>
              {/* </React.Fragment> */}
              {/* ))} */}
            </table>

            <div>
              <h2 className="flex items-end justify-end text-gray-800 text-4xl font-bold">
                Total. {(278.6).toLocaleString()}
              </h2>
            </div>

            <section className="mt-10 mb-5">
              <h3>Additional notes</h3>
              <p className="lg:w-1/2 text-justify">
                notes' is assigned a value but never used{" "}
              </p>
            </section>

            <div>
              <footer className="footer border-t-2 border-gray-300 pt-5">
                <ul className="flex flex-wrap items-center justify-center">
                  <li>
                    <span className="font-bold"> Name:</span> menberu
                  </li>
                  <li>
                    <span className="font-bold"> Email:</span>{" "}
                    walelgnkelem345@gmail.com
                  </li>
                  <li>
                    <span className="font-bold">Phone number:</span> 0943064628
                  </li>
                  <li>
                    <span className="font-bold">Bank:</span> menberu walelgn
                    kelem
                  </li>
                  <li>
                    <span className="font-bold">Account holder:</span> cbe
                  </li>
                  <li>
                    <span className="font-bold">Account number:</span>{" "}
                    100007896342
                  </li>
                  <li>
                    <span className="font-bold">Website:</span>{" "}
                    <a href="#" target="_blank" rel="noopenner noreferrer">
                      www.eplusapp.et
                    </a>
                  </li>
                </ul>
              </footer>

              <p className="text-center px-5 mt-8 text-xs ">
                Invoicer is built by{" "}
                <a
                  href="https://tsbsankara.com"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  Eplusapp software development company
                </a>
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default Invoicer;
