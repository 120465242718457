import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Brl from "../../../utils/BaseUrl";
import Layout from "../../layout/layout";
import { AiFillDelete } from "react-icons/ai";
import { removeFromCarts } from "../../../actions/cartAction";
import { getCartTotal } from "../../../actions/cartAction";
import Pay from "../payment/Pay";
import {
  increaseItemQuantity,
  decreaseItemQuantity,
} from "../../../actions/cartAction";

const CartPage = () => {
  const { carts, totalQuantity, totalPrice } = useSelector(
    (state) => state.carts
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCartTotal());
  }, [carts]);

  const removeFromCartHandler = (data) => {
    // console.log("remove item is :", data);
    dispatch(removeFromCarts(data));
  };

  const increaseCartHandler = (data) => {
    dispatch(increaseItemQuantity(data));
  };

  return (
    <Layout>
      <div className="bg-[#E3E6E6]">
        <section className="h-100 ">
          <div className="py-5 ">
            <div className="md:flex justify-content-center my-4">
              <div className="mx-8 bg-white rounded-lg">
                <div className=" m-4">
                  <div className="card-header py-3">
                    <h5 className="mb-4 font-serif">
                      There are - {carts.length} items inside the cart{" "}
                    </h5>
                  </div>
                  <div className="mx-6">
                    {carts?.map((data) => (
                      <div>
                        <div className="flex">
                          <div className="space-x-2 col-md-12 mb-2 mb-lg-0">
                            <div
                              className="hover-overlay hover-zoom rounded"
                              data-mdb-ripple-color="light"
                            >
                              <img
                                //  src={`${Brl}/images/${data.featureImage}`}
                                src={data?.linkImg}
                                className="w-24 h-24"
                                alt="defualt product images"
                              />
                            </div>
                          </div>

                          <div className="mx-4 mb-lg-0">
                            <p>
                              <strong>{data?.name}</strong>
                            </p>

                            <p className="text-center text-md-center pt-4">
                              <strong>{data?.price}</strong>
                            </p>
                          </div>

                          <div className="mb-2 mb-lg-0 md:ml-12 lg:ml-12 ml-6">
                            <div
                              className="flex mb-4"
                              style={{ maxWidth: "300px" }}
                            >
                              <button
                                className="btn btn-primary px-3 me-2 bg-[#E3E6E6]"
                                onClick={() =>
                                  dispatch(decreaseItemQuantity(data))
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="black"
                                  class="w-5 h-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M18 12H6"
                                  />
                                </svg>
                              </button>

                              <div className="p-1">
                                <input
                                  id="form1"
                                  min="0"
                                  name="quantity"
                                  value={data.quantity}
                                  type="number"
                                  className="w-8"
                                  onChange={() => null}
                                />
                              </div>

                              <button
                                className="btn btn-primary px-3 ms-2 bg-[#E3E6E6]"
                                onClick={() => increaseCartHandler(data)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="black"
                                  class="w-5 h-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 6v12m6-6H6"
                                  />
                                </svg>
                              </button>
                            </div>
                            <button
                              type="button"
                              className="text-sm ml-24"
                              title="Remove product"
                              onClick={() => removeFromCartHandler(data)}
                            >
                              <AiFillDelete className=" h-6 w-6" />
                            </button>
                          </div>
                        </div>
                        <hr class="w-full h-0.5 mx-auto bg-gray-300 border-0 rounded md:my-4 dark:bg-gray-700" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className=" bg-white h-52">
                <div className=" m-4">
                  <div className="card-header py-3">
                    <h5 className="mb-0 font-serif">Total Price</h5>
                  </div>
                  <hr class="w-full h-0.5 mx-auto bg-gray-300 border-0 rounded md:my-4 dark:bg-gray-700" />
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0 font-serif">
                        Total Quantity
                        <span className="lg:pl-24 md:pl-24 pl-12">
                          {totalQuantity}
                        </span>
                      </li>

                      <li className="list-group-item flex justify-content-between align-items-center border-0 px-0 mb-3 font-serif">
                        <div>
                          <strong>Total</strong>
                        </div>
                        <span>
                          <strong className="lg:pl-32 md:pl-32 pl-12">
                            {totalPrice}
                          </strong>
                        </span>
                      </li>
                    </ul>
                    <Pay amount={totalPrice} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default CartPage;
