import React from "react";
import { Route, Routes } from "react-router-dom";
import Manufacture from "./components/pages/manufacture/manufacture";
import Product from "./components/pages/products/product";
import Orders from "./components/pages/products/orders";
import ProductRate from "./components/pages/products/product_rate";
import ManufactureRate from "./components/pages/manufacture/manufacture_rate";
import ProductLike from "./components/pages/products/product_like";
import Invoicer from "./components/pages/products/invoicer";
import Imageslider from "./components/pages/imageslider";
import Gallery from "./components/pages/gallery";
import Cart from "./components/pages/products/cartdetail";
import Search from "./components/pages/products/search_product";
import Category from "./components/pages/products/product_category";
import AllCategory from "./components/pages/products/allproductcategory";
import Manufacture_detail from "./components/pages/manufacture/manufacture_detail";
import Appp from "./components/pages/Appp";

function App() {
  var hours = 1; // to clear the localStorage after 1 hour
  // (if someone want to clear after 8hrs simply change hours=8)
  var now = new Date().getTime();
  var setupTime = localStorage.getItem("setupTime");
  if (setupTime == null) {
    localStorage.setItem("setupTime", now);
  } else {
    if (now - setupTime > hours * 60 * 60 * 1000) {
      localStorage.clear();
      localStorage.setItem("setupTime", now);
    }
  }
  return (
    <>
      <Routes>
        <Route path="/" element={<Product />}></Route>
        {/* <Route path="/product/:id" element={<Product_detail />}></Route> */}
        <Route path="/product/orders/:id" element={<Orders />}></Route>
        <Route path="/product/rate/:id" element={<ProductRate />}></Route>
        <Route path="/product/like/:id" element={<ProductLike />}></Route>
        <Route path="/imageslider" element={<Imageslider />}></Route>
        <Route path="/gallery" element={<Gallery />}></Route>
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/searchProduct/:Pname" element={<Search />}></Route>
        <Route path="/productCategory" element={<AllCategory />}></Route>
        <Route path="/productCategory/:Cname" element={<Category />}></Route>
        <Route path="/product/invoicer" element={<Invoicer />}></Route>
        <Route path="/manufacture" element={<Manufacture />}></Route>
        <Route path="/manufacture/:id" element={<Manufacture_detail />}></Route>
        <Route
          path="/manufacture/rate/:id"
          element={<ManufactureRate />}
        ></Route>
        <Route path="/Appp" element={<Appp />}></Route>
      </Routes>
    </>
  );
}

export default App;
