// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { handleError } from "../utils/toast";

//login users
export const loginUser = createAsyncThunk(
  "user",
  async (authData, thunkAPI) => {
    try {
      // base url

      let baseUrl = "http://localhost:11215/addressapi/users/login";

      // response
      let response = await axios.post(baseUrl, authData);
      //  console.log(response)
      // condition
      if (response.status === 200) {
        // handleSuccess(response.data.message);
        // put the user info into local storage
        localStorage.setItem("userInfo", JSON.stringify(response.data));

        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      console.log(e.response);

      handleError(e.response.data.message);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const viewUser = createAsyncThunk("user", async (thunkAPI) => {
  try {
    // base url

    let baseUrl = "http://192.168.1.33:11215/addressapi/users/login";

    // response
    let response = await axios.post(baseUrl);
    //  console.log(response)
    // condition
    if (response.status === 200) {
      // handleSuccess(response.data.message);
      // put the user info into local storage
      localStorage.setItem("userInfo", JSON.stringify(response.data));
      return response.data;
    } else {
      handleError(response.message);
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (e) {
    console.log(e.response);

    handleError(e.response.data.message);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});
