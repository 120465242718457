import React, { useState } from "react";
import { useEffect } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import discount from "../../../img/icon/discount1.png";
import notes from "../../../img/icon/mm.png";
import { useDispatch, useSelector } from "react-redux";
import Loading1 from "../loading";
import { RxDotFilled } from "react-icons/rx";
import AddressBaseUrl from "../../../utils/BaseUrl";

const Product_detail = ({ productDetail2 }) => {
  const slides = [
    `https://images.unsplash.com/photo-1661961112951-f2bfd1f253ce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2672&q=80`,
    "https://images.unsplash.com/photo-1496181133206-80ce9b88a853?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2671&q=80",
    "https://images.unsplash.com/photo-1661961112951-f2bfd1f253ce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2672&q=80",
  ];
  const { productDetail } = useSelector((state) => state.productDetail);
  // console.log("product detial 12 : ", productDetail.galleryImage);
  const [currentIndex, setCurrentIndex] = useState(0);
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentIndex === 2) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [currentIndex]);

  return (
    <>
      <div className="md:h-screen w-full md:flex flex-wrap m-auto">
        <div className="h-full md:w-[100%] w-full  m-auto relative group ">
          <div className="w-full h-[100%] bg-center duration-500 relative overflow-hidden bg-cover bg-no-repeat">
            {productDetail.galleryImage &&
            productDetail.galleryImage[currentIndex]?.imageName ? (
              <img
                src={`${AddressBaseUrl}/images/${
                  productDetail?.galleryImage &&
                  productDetail?.galleryImage[currentIndex]?.imageName
                }`}
                className="h-full w-full"
                alt="images not found"
              />
            ) : (
              <img
                src={slides[currentIndex]}
                className="h-full w-full"
                alt="images not found"
              />
            )}
          </div>

          {/* Left Arrow */}
          <div className="hidden group-hover:block absolute top-[25%] -translate-x-0 translate-y-[-50%] left-0 text-2xl py-2 md:py-12 lg:py-12 hover:border-2 border-black hover:bg-black/20 text-black cursor-pointer">
            <BsChevronCompactLeft onClick={prevSlide} className="h-12 w-12" />
          </div>
          <div class="font-serif flex md:hidden lg:hidden opacity-100 duration-300 absolute inset-0  flex justify-center items-center text-3xl text-white font-semibold italic">
          Eplusapp
          </div>

          {/* Right Arrow */}
          <div className="hidden group-hover:block absolute top-[25%] -translate-x-0 translate-y-[-50%] right-0 text-2xl hover:border-2 py-2 md:py-12 lg:py-12 hover:bg-black/20 border-black text-black cursor-pointer z-10">
            <BsChevronCompactRight onClick={nextSlide} className="h-12 w-12" />
          </div>
          <div class="mix-blend-hard-light hidden md:flex lg:w-3/12 md:w-2/12 absolute bottom-0 right-0 top-12 h-full overflow-hidden bg-fixed">
            <div className="m-auto">
              <div className="bg-[#E4E4E4] m-2 mx-2 mb-4 flex">
                <h1 className="py-2 pl-4 font-serif">
                Eplusapp shopping context with reliable quality at the best price !
                </h1>
                <img src={discount} className="h-24 w-32" />
              </div>

              <div className="bg-[#E4E4E4] m-2 mb-4 flex">
                <h1 className="p-4 font-serif">If you have a question? </h1>
                <img src={notes} className="h-24 w-24 p-2" />
              </div>

              <div className="m-2">
                <ul className="space-y-6">
                  <li className="">
                    <div class=" text-md">
                      <a
                        href="#"
                        className="bg-[#0397FF] text-white font-semibold py-1 px-16 rounded-xl h-full font-serif"
                      >
                        Digital Address
                      </a>
                    </div>
                  </li>
                  <li className="">
                    <div class="text-md ">
                      <a
                        href="#"
                        className="font-serif bg-white text-black border-2 font-semibold py-1 px-24 rounded-xl w-full h-full"
                      >
                        Login
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="w-full md:w-[15%] h-full bg-gradient-to-r from-[#757571] to-[#676763] p-4 lgl:p-8 shadow-shadowOne flex flex-col gap-8 justify-center">
          <div className="flex flex-col gap-4">
            <h3 className="flex text-3xl font-bold text-black">
              {productDetail?.name || "ኢጵላሣጵ"}
            </h3>
            <p className="text-md font-normal text-gray-800">
              {productDetail?.description ||
                "ኢጵላሣጵ አዲስ አሰራር [እይት]፣ [ትኩርና] ዲጂታል የንግድ ተቋም [ምስርት] ነው። ኢጵላሣጵ ለመረጃ አጠቃቀም፣ ደኅንነት፣ ልውውጥና ክምችት(አደልክ) የሚረዱ መጥቀቶችን ወይም ሙያዊ አገልግሎቶችን [ፍልግ]፣ [ንድፍ]፣ [ፍብርክ]፣ [ሙክር]፣ [ጥግንና] ነው።"}
            </p>
            <p className="text-base text-gray-600 items-center gap-2">
              ዋጋ:{" "}
              <span className="text-black">
                {productDetail?.price || "45000 "} ብር
              </span>
            </p>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default Product_detail;
