import Product from "./Product";
import { useState } from "react";
function Appp() {
  const [products, setProducts] = useState([
    { id: 1, name: "Product A", price: 10 },
    { id: 2, name: "Product B", price: 15 },
    { id: 3, name: "Product C", price: 20 },
  ]);
  const [likedProducts, setLikedProducts] = useState([]);
  const [dislikedProducts, setDislikedProducts] = useState([]);

  const handleLike = (productId) => {
    setLikedProducts([...likedProducts, productId]);
  };

  const handleDislike = (productId) => {
    setDislikedProducts([...dislikedProducts, productId]);
  };

  return (
    <div className="App">
      <h1>E-commerce Products</h1>
      <div className="product-list">
        {products.map((product) => (
          <Product
            key={product.id}
            product={product}
            onLike={handleLike}
            onDislike={handleDislike}
          />
        ))}
      </div>
      <div>
        <h2>Liked Products</h2>
        <ul>
          {likedProducts.map((productId) => (
            <li key={productId}>{`Product ${productId}`}</li>
          ))}
        </ul>
      </div>
      <div>
        <h2>Disliked Products</h2>
        <ul>
          {dislikedProducts.map((productId) => (
            <li key={productId}>{`Product ${productId}`}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Appp;
