import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imgGirl from "../../../img/ecommerce.jpg";
import { viewProducts } from "../../../actions/productAction";
import { useDispatch, useSelector } from "react-redux";
import AddressBaseUrl from "../../../utils/BaseUrl";
import Loading from "../loading";
import { useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { viewProductRate } from "../../../actions/productRateAction";
import { dataProducts } from "../data";

function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "hidden",
        background: "#E3E6E6",
        margin: "24px",
        zIndex: "5",
        width: 30,
        height: 80,
        paddingTop: "30px",
      }}
      onClick={onClick}
    />
  );
}

function Topsellsproduct() {
  const [defaultImage, setDefaultImage] = useState({});
  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 9,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    appendDots: (dots) => (
      <div
        style={{
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleErrorImage = (data) => {
    setDefaultImage((prev) => ({
      ...prev,
      [data.target.alt]: data.target.alt,
      linkDefault: imgGirl,
    }));
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { product } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(viewProducts());
  }, []);

  const { productRate } = useSelector((state) => state.productRate);
  useEffect(() => {
    dispatch(viewProductRate());
  }, []);
  const rateIndex = productRate.length;
  let Total = 0;
  let newData1 = Array.prototype.map.call(productRate, (item) => {
    Total = +Total + +item.rating;
  });
  const totalRating = Total / rateIndex;
  console.log("rate : ", totalRating);
  const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9",
  };

  const styles = {
    stars: {
      display: "flex",
      flexDirection: "row",
    },
  };
  const stars = Array(5).fill(0);
  const handleGetOrder = (id) => {
    navigate("/product/orders/" + id);
  };

  return (
    <>
      <div className="bg-[#E3E6E6]">
        <div className="w-11/12 xl:w-11/12">
          <section className="mb-6 text-gray-800 text-center ">
            <div
              style={{}}
              className=" md:flex flex-wrap justify-between items-center mx-auto md:px-6 lg:px-6 px-1"
            >
              <div className="items-center">
                <div>
                  <button className="mr-6 text-lg font-display text-black font-medium hover:text-[#0397FF] ">
                    <span className="font-serif underline decoration-pink-800 decoration-4 underline-offset-8">
                      New
                    </span>{" "}
                    products
                  </button>
                </div>
                <div className="pt-5">
                  {dataProducts?.length > 0 ? (
                    dataProducts?.slice(0, 2).map((item, index) => (
                      <>
                        <div
                          key={index}
                          className="lg:mb-0 md:pt-4 lg:pt-4 pt-2 flex"
                        >
                          <div className="relative group block bg-white lg:ml-12 md:ml-12 ml-6">
                            <div
                              className="p-2 relative overflow-hidden bg-no-repeat bg-cover relative overflow-hidden bg-no-repeat bg-cover rounded-lg"
                              data-mdb-ripple="true"
                              data-mdb-ripple-color="light"
                            >
                              <img
                                onClick={() => handleGetOrder(item.id)}
                                className="w-24 h-24 transition cursor-pointer duration-700"
                                //  src={`${AddressBaseUrl}/images/${item.featureImage}`}
                                src={item?.linkImg}
                                alt="product img not found"
                              />
                            </div>
                          </div>
                          <div className="ml-4">
                            <div className="m-auto justify-start">
                              <p className="text-sm">
                                {item.name.substring(0, 6)}
                              </p>
                            </div>

                            <div
                              style={styles.stars}
                              className="m-auto justify-start"
                            >
                              {stars.map((_, index) => {
                                return (
                                  <FaStar
                                    key={index}
                                    size={12}
                                    color={
                                      2 > index ? colors.orange : colors.grey
                                    }
                                    style={{
                                      marginRight: 3,
                                      cursor: "pointer",
                                    }}
                                    {...register(`rating`)}
                                    values={2}
                                  />
                                );
                              })}
                              <h1 className="text-sm">{rateIndex}</h1>
                            </div>
                            <div className="m-auto justify-start">
                              <p className="text-lg font-bold font-serif">
                                {item.price} birr
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="items-center py-3">
                <div>
                  <button className="mr-6 text-lg font-display text-black font-medium hover:text-[#0397FF]">
                    <span className="underline decoration-pink-800 decoration-4 underline-offset-8 font-serif">
                      {" "}
                      Heigh sold
                    </span>{" "}
                    products
                  </button>
                </div>
                <div className="pt-5">
                  {dataProducts?.length > 0 ? (
                    dataProducts?.slice(3, 5).map((item, index) => (
                      <>
                        <div
                          key={index}
                          className="lg:mb-0 md:pt-4 lg:pt-4 pt-2 flex"
                        >
                          <div className="relative group block bg-white lg:ml-12 md:ml-12 ml-6">
                            <div
                              className="p-2 relative overflow-hidden bg-no-repeat bg-cover relative overflow-hidden bg-no-repeat bg-cover rounded-lg"
                              data-mdb-ripple="true"
                              data-mdb-ripple-color="light"
                            >
                              <img
                                onClick={() => handleGetOrder(item.id)}
                                className="w-24 h-24 transition cursor-pointer duration-700"
                                // src={`${AddressBaseUrl}/images/${item.featureImage}`}
                                src={item?.linkImg}
                                alt="product img not found"
                              />
                            </div>
                          </div>
                          <div className="ml-4">
                            <div className="m-auto justify-start">
                              <p className="text-sm">
                                {item.name.substring(0, 6)}
                              </p>
                            </div>

                            <div
                              style={styles.stars}
                              className="m-auto justify-start"
                            >
                              {stars.map((_, index) => {
                                return (
                                  <FaStar
                                    key={index}
                                    size={12}
                                    color={
                                      3 > index ? colors.orange : colors.grey
                                    }
                                    style={{
                                      marginRight: 3,
                                      cursor: "pointer",
                                    }}
                                    {...register(`rating`)}
                                    values={3}
                                  />
                                );
                              })}
                              <h1 className="text-sm">{rateIndex}</h1>
                            </div>
                            <div className="m-auto justify-start">
                              <p className="text-lg font-bold font-serif">
                                {item.price} birr
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="items-center py-3">
                <div>
                  <button className="mr-6 text-lg font-display text-black font-medium hover:text-[#0397FF]">
                    <span className="underline decoration-pink-800 decoration-4 underline-offset-8 font-serif">
                      Coming
                    </span>{" "}
                    soon
                  </button>
                </div>
                <div className="pt-5">
                  {dataProducts?.length > 0 ? (
                    dataProducts?.slice(6, 8).map((item, index) => (
                      <>
                        <div
                          key={index}
                          className="lg:mb-0 md:pt-4 lg:pt-4 pt-2 flex"
                        >
                          <div className="relative group block bg-white lg:ml-12 md:ml-12 ml-6">
                            <div
                              className="p-2 relative overflow-hidden bg-no-repeat bg-cover relative overflow-hidden bg-no-repeat bg-cover rounded-lg"
                              data-mdb-ripple="true"
                              data-mdb-ripple-color="light"
                            >
                              <img
                                onClick={() => handleGetOrder(item.id)}
                                className="w-24 h-24 transition cursor-pointer duration-700"
                                // src={`${AddressBaseUrl}/images/${item.featureImage}`}
                                src={item?.linkImg}
                                alt="product img not found"
                              />
                            </div>
                          </div>
                          <div className="ml-4">
                            <div className="m-auto justify-start">
                              <p className="text-sm">
                                {item.name.substring(0, 6)}
                              </p>
                            </div>

                            <div
                              style={styles.stars}
                              className="m-auto justify-start"
                            >
                              {stars.map((_, index) => {
                                return (
                                  <FaStar
                                    key={index}
                                    size={12}
                                    color={
                                      4 > index ? colors.orange : colors.grey
                                    }
                                    style={{
                                      marginRight: 3,
                                      cursor: "pointer",
                                    }}
                                    {...register(`rating`)}
                                    values={4}
                                  />
                                );
                              })}
                              <h1 className="text-sm">{rateIndex}</h1>
                            </div>
                            <div className="m-auto justify-start">
                              <p className="text-lg font-bold font-serif">
                                {item.price} birr
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Topsellsproduct;
