// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import AddressBaseUrl from "../utils/BaseUrl";
import { handleError } from "../utils/toast";

export const viewProducts = createAsyncThunk("product", async (thunkAPI) => {
  try {
    let URL = `${AddressBaseUrl}/addressapi/products/all`;

    // let response = await createContact(message);
    let response = await axios.get(URL);
    //  console.log(response);
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response.message);
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (e) {
    console.log("Error", e.response.data);
    handleError("loading...");
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const ProductOrder = createAsyncThunk(
  "product",
  async (orderData, thunkAPI) => {
    try {
      console.log("product Info", orderData);

      let URL = `https://ecommerce-backend.eplusapp.et/ecommerceapi/orders/create`;

      // let response = await createContact(message);
      let response = await axios.post(URL, orderData);

      console.log(response);

      if (response.status === 200) {
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      handleError("loading...");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addProductRate = createAsyncThunk(
  "product",
  async (reviewData, thunkAPI) => {
    try {
      console.log(reviewData);
      let URL = `https://ecommerce-backend.eplusapp.et/ecommerceapi/ratereview/create`;

      // let response = await createContact(message);
      let response = await axios.post(URL, reviewData);

      console.log(response);

      if (response.status === 200) {
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      handleError("loading...");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addProductLike = createAsyncThunk(
  "product",
  async (likeData, thunkAPI) => {
    try {
      //   console.log("post data : ", likeData);
      let URL = `https://ecommerce-backend.eplusapp.et/ecommerceapi/favorites/create`;

      // let response = await createContact(message);
      let response = await axios.post(URL, likeData);

      console.log(response);

      if (response.status === 200) {
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      handleError("loading...");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const searchProduct = createAsyncThunk(
  "product",
  async (Pname, thunkAPI) => {
    try {
      // base url
      // console.log("product action page", Pname);

      let baseUrl = `${AddressBaseUrl}/addressapi/products/search?key=${Pname}`;
      // response
      let response = await axios.get(baseUrl);
      // let response = await createContact(message);
      if (response.status === 200) {
        console.log(response.data);
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      handleError("search single product...");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const productCategory = createAsyncThunk(
  "product",
  async (Cname, thunkAPI) => {
    try {
      // base url
      // console.log("product action page", Pname);

      let baseUrl = `${AddressBaseUrl}/addressapi/products/search?key=${Cname}`;
      // response
      let response = await axios.get(baseUrl);
      // let response = await createContact(message);
      if (response.status === 200) {
        console.log(response.data);
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      handleError("search single product...");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
