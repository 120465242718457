// import { createStore } from 'redux'
import { configureStore } from "@reduxjs/toolkit";
import manufactureReducer from "../reducers/manufacturReducer";
import productReducer from "../reducers/productReducer";
import userReducer from "../reducers/userReducer";
import productRateReducer from "../reducers/productRateReducer";
import productLikeReducer from "../reducers/productLikeReducer";
import productOrderReducer from "../reducers/productOrderReducer";
import productCategoryReducer from "../reducers/productCategoryReducer";
import { cartReducer } from "../reducers/cartReducer";
import paymentReducer from "../reducers/paymentReducer";
import productDetailReducer from "../reducers/productDetailReducer";
import singleProductReducer from "../reducers/singleProductReducer";

const store = configureStore({
  reducer: {
    manufacture: manufactureReducer,
    carts: cartReducer,
    product: productReducer,
    productRate: productRateReducer,
    productLike: productLikeReducer,
    productOrder: productOrderReducer,
    productCategory: productCategoryReducer,
    // carts: cartReducer,
    payment: paymentReducer,
    user: userReducer,
    productDetail: productDetailReducer,
    singleProduct: singleProductReducer,
  },
});

export default store;
