import { React, useEffect, useState } from "react";
import { BiCartDownload } from "react-icons/bi";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCartTotal } from "../../actions/cartAction";
import { useTranslation } from "react-i18next";
import AddressBaseUrl from "../../utils/BaseUrl";
import { dataProducts } from "../pages/data";

const TopHeader = () => {
  const { carts, totalQuantity } = useSelector((state) => state.carts);

  const { product } = useSelector((state) => state.product);
  // console.log("top header products ", product);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCartTotal());
  }, [carts]);

  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = dataProducts?.filter((value) => {
      return value.name.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
      // console.log("product name :", wordEntered);
    }
  };

  const navLinkStyles = ({ isActive }) => {
    return {
      ml: isActive ? "4" : "28",
      position: isActive ? "right" : "left",
      //color: isActive ? "#fff" : "#72C3EE",
    };
  };
  const { t: translate, i18n } = useTranslation(["header"]);
  const handleLangChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };
  const currentLanguage = localStorage.getItem("i18nextLng") || "";
  return (
    <>
      <div
        style={{
          background: "#393944",
        }}
        className="hidden md:flex flex-wrap justify-center items-center h-12"
      >
        <div className="flex">
          <div className="items-center left-48">
            <div className="">
              <div className="rounded-full flex bg-[#1b1f27] w-64 h-9">
                <NavLink
                  style={navLinkStyles}
                  to="/"
                  className="ml-1 text-lg font-bold absolute pl-1 font-serif mt-1 [&.active]:text-[#fff] text-[#0396ffe0] [&.active]:bg-[#0396ffc2] [&.active]:rounded-full [&.active]:w-[85px] [&.active]:h-7"
                >
                  Products
                </NavLink>
                <NavLink
                  style={navLinkStyles}
                  to="/manufacture"
                  className="ml-[97px] pl-1 text-xl font-bold font-serif absolute [&.active]:text-[#fff] text-[#0396ffe0] [&.active]:bg-[#0396ffc2] [&.active]:rounded-full [&.active]:w-[150px] [&.active]:h-7 mt-1"
                >
                  Manufacturers
                </NavLink>
              </div>
            </div>
          </div>

          <div className="relative flex md:ml-0 md:pl-12">
            <form onSubmit={handleFilter}>
              <div class=" flex w-full items-stretch z-10">
                <input
                  type="search"
                  class="lg:w-[400px] md:w-[400px] w-[150px] font-serif rounded-r border-b-[1px] border-b-gray-500 bg-[#151511] text-white px-4 py-1.5 active:outline-none focus-visible:outline-[#0397FF] outline-none focus-visible:border-b-transparent duration-300 resize-none"
                  placeholder="search products"
                  aria-label="Search"
                  aria-describedby="button-addon1"
                  value={wordEntered}
                  onChange={handleFilter}
                />
                <button
                  class="absolute font-serif flex right-0 items-center rounded-r md:px-6 px-0 py-1.5 bg-[#0b0b0d] text-white tracking-wider hover:text-white duration-300 hover:border-[1px] hover:border-[#0397FF] border-transparent"
                  type="submit"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="h-3 w-3 md:h-5 md:w-5"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Search
                </button>
              </div>
              <div>
                {filteredData.length != 0 && (
                  <div className="felx m-auto absolute bg-white divide-y mt-2 divide-gray-100 rounded-lg shadow w-72 dark:bg-gray-700 dark:divide-gray-600">
                    {filteredData.slice(0, 10).map((value, key) => {
                      return (
                        <>
                          <div className="flex ml-2">
                            <img
                              className="h-8 w-8"
                              //src={`${AddressBaseUrl}/images/${value.featureImage}`}
                              src={value?.linkImg}
                            />
                            <a
                              className="pl-2"
                              href={`/searchProduct/${value.categoryName}`}
                            >
                              <p
                                className="text-black text-center cursor-pointer"
                                type="submit"
                              >
                                {value.name}{" "}
                              </p>
                            </a>
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            </form>
          </div>

          <div className=" absolute items-center right-12">
            {/* <div className="flex h-4 w-4 items-center justify-center rounded-full border-2 ml-4"><p className='text-xs'>5</p></div> */}
            <Link to="/cart" className="text-white flex">
              <BiCartDownload className="w-8 h-8" />
              <p>[{totalQuantity}]</p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopHeader;
