import React, { useState } from "react";

const Product = ({ product, onLike, onDislike }) => {
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);

  const handleLike = () => {
    setLiked(true);
    onLike(product.id);
  };

  const handleDislike = () => {
    setDisliked(true);
    onDislike(product.id);
  };

  return (
    <div className="product">
      <h3>{product.name}</h3>
      <p>Price: {product.price}</p>
      <button onClick={handleLike} disabled={liked}>
        Like
      </button>
      <button onClick={handleDislike} disabled={disliked}>
        Dislike
      </button>
    </div>
  );
};

export default Product;
