import { createSlice } from "@reduxjs/toolkit";
import { viewProducts } from "../actions/productAction";
import { searchProduct } from "../actions/productAction";
import { ProductOrder } from "../actions/productAction";
import { addProductLike } from "../actions/productAction";
import { addProductRate, productCategory } from "../actions/productAction";

// initial state
const initialState = {
  loading: false,
  product: [],
  product: {},
  error: false,
  success: false,
  message: null,
};

// products slice
const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: {
    // view users
    [viewProducts.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [viewProducts.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.product = payload;
    },
    [viewProducts.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // search single product
    [searchProduct.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [searchProduct.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.product = payload;
    },
    [searchProduct.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    //search products using category
    [productCategory.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [productCategory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.product = payload;
    },
    [productCategory.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // add product orders
    [ProductOrder.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [ProductOrder.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.product = payload;
    },
    [ProductOrder.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // add product likes
    [addProductLike.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [addProductLike.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.product = payload;
    },
    [addProductLike.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

// export
export default productSlice.reducer;
