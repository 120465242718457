import { createSlice } from "@reduxjs/toolkit";
import { addProductLike, viewProductLike } from "../actions/productLikeAction";

// initial state
const initialState = {
  loading: false,
  error: false,
  success: false,
  message: null,
  productLike: [],
};

// productLike slice
const productLikeSlice = createSlice({
  name: "productLike",
  initialState,
  reducers: {},
  extraReducers: {
    // add product likes
    [addProductLike.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [addProductLike.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.productLike = payload;
    },
    [addProductLike.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // view product likes
    [viewProductLike.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [viewProductLike.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.productLike = payload;
      //console.log(payload);
    },
    [viewProductLike.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

// export
export default productLikeSlice.reducer;
