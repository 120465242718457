import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/layout";
import {
  addProductLike,
  viewProductLike,
} from "../../../actions/productLikeAction";
import { loginUser } from "../../../actions/usersAction";
import { HiOutlineX } from "react-icons/hi";
import AddressBaseUrl from "../../../utils/BaseUrl";
import Loading from "../loading";
import { getProduct } from "../../../actions/searchProductAction";
import product3 from "../../../img/img1.jpg";

const ProductLike = () => {
  const [open, setOpen] = useState(false);
  // const [like, setLike] = useState(false);
  // const [dislike, setDislike] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const likeInfo = localStorage.getItem("likeInfo")
    ? JSON.parse(localStorage.getItem("likeInfo"))
    : null;
  console.log("product like information are : ", likeInfo);
  function Logout() {
    localStorage.clear();
    // navigate("/");
  }
  if (userInfo == null) {
    navigate("/");
  }

  const { id } = useParams();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  // use form
  const {
    handleSubmit: handleLoginSubmit,
    register: registerLogin,
    formState: { errors: loginErrors },
  } = useForm();

  // use selector
  const { isLogin, loading, user } = useSelector((state) => state.user);

  const { product } = useSelector((state) => state.product);
  useEffect(() => {
    dispatch(getProduct(id));
  }, []);
  const productImg = product.featureImage;

  // const { productLike } = useSelector(
  //   (state) => state.productLike
  // );

  // useEffect(() => {
  //   dispatch(viewProductLike(id));
  // },[]);

  // console.log(userInfo.id);
  // console.log(productLike.userid);
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);

  const onSubmitLike = (data) => {
    console.log("action is here");
    // pass the data form data
    const formData = new FormData();
    Object?.entries(data).map((entry) => {
      const [key, value] = entry;

      formData.append(key, value);
    });
    console.log("send product like functionality :", data);
    dispatch(addProductLike(data));
  };

  // login
  const handleLogin = (data) => {
    dispatch(loginUser(data));
  };

  const handeleLike = () => {
    if (!like) {
      setLike(true);
    } else {
      setLike(false);
    }
  };
  const handeleDislike = () => {
    if (!dislike) {
      setDislike(true);
    } else {
      setDislike(false);
    }
  };
  return (
    <Layout>
      <div className="pt-6 pb-4 bg-[#E3E6E6]">
        <form onSubmit={handleSubmit(onSubmitLike)}>
          <div className=" md:flex w-11/12 h-full m-auto relative bg-white">
            <div className="mb-6 lg:mb-0">
              <div className="relative md:flex block">
                <div className="flex">
                  <div
                    className="relative overflow-hidden bg-no-repeat bg-cover relative overflow-hidden bg-no-repeat bg-cover shadow-lg rounded-lg"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                  >
                    <img
                      className="w-screen h-72 md:h-80 lg:h-80 transition cursor-pointer duration-700"
                      // src={`${AddressBaseUrl}/images/${productImg}`}
                      src={product3}
                      alt="news img"
                    />
                  </div>
                </div>

                <div className=" md:mx-6 mx-2">
                  {/* <div className="relative  md:col-span-2 md:my-3">
               <h1 className="italic font-bold">{product?.name}</h1>
               <h1>{product?.description}</h1>
             </div> */}

                  {/* <div className=" relative flex md:col-span-2 md:my-3">
              <input class="appearance-none block w-full text-gray-700 py-6 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
               type="text"
               placeholder="productId"
               {...register("productId")}
                value={product?.id}
              />
            </div> */}

                  <input
                    className="hidden"
                    value={id}
                    {...register("productId")}
                  />
                  <input
                    className="hidden"
                    value={userInfo?.id}
                    {...register("userId")}
                  />
                  {/* <div className=" relative flex md:col-span-2 md:my-3">
              <input class="appearance-none block w-full text-gray-700 py-6 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
               type="text"
               placeholder="userId"
               {...register("userId", {
                required: true,
                })}
                value={userInfo?.id}
              />
            </div> */}

                  {like ? (
                    <button
                      onClick={handeleLike}
                      className="font-bold py-2 px-4 rounded"
                      type="radio"
                      value="true"
                      {...register("like")}
                    >
                      {/* {productLike?.likes} K */}1k
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="black"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 ml-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                        />
                      </svg>
                    </button>
                  ) : (
                    <button
                      onClick={handeleLike}
                      className="font-bold py-2 px-4 rounded"
                      type="radio"
                      value="true"
                      {...register("like")}
                    >
                      {/* {productLike?.likes} p */}2 k
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 ml-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                        />
                      </svg>
                    </button>
                  )}
                  {/* <button onClick={Logout}>logout</button> */}

                  {/* {dislike ? (

<button
    onClick={handeleDislike}
    className="font-bold py-2 px-4 rounded"
    type="radio"
    value="true"
    {...register("dislike")}
   >
              {productLike?.dislikes} K 
              <svg xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 15h2.25m8.024-9.75c.011.05.028.1.052.148.591 1.2.924 2.55.924 3.977a8.96 8.96 0 01-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398C20.613 14.547 19.833 15 19 15h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 00.303-.54m.023-8.25H16.48a4.5 4.5 0 01-1.423-.23l-3.114-1.04a4.5 4.5 0 00-1.423-.23H6.504c-.618 0-1.217.247-1.605.729A11.95 11.95 0 002.25 12c0 .434.023.863.068 1.285C2.427 14.306 3.346 15 4.372 15h3.126c.618 0 .991.724.725 1.282A7.471 7.471 0 007.5 19.5a2.25 2.25 0 002.25 2.25.75.75 0 00.75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 002.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384" />
              </svg>                 
</button>
):(
<button
    onClick={handeleDislike}
    className="font-bold py-2 px-4 rounded"
    type="radio"
    value="false"
    {...register("dislike")}
   >
              {productLike?.dislikes} K
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 15h2.25m8.024-9.75c.011.05.028.1.052.148.591 1.2.924 2.55.924 3.977a8.96 8.96 0 01-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398C20.613 14.547 19.833 15 19 15h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 00.303-.54m.023-8.25H16.48a4.5 4.5 0 01-1.423-.23l-3.114-1.04a4.5 4.5 0 00-1.423-.23H6.504c-.618 0-1.217.247-1.605.729A11.95 11.95 0 002.25 12c0 .434.023.863.068 1.285C2.427 14.306 3.346 15 4.372 15h3.126c.618 0 .991.724.725 1.282A7.471 7.471 0 007.5 19.5a2.25 2.25 0 002.25 2.25.75.75 0 00.75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 002.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384" />
              </svg>
</button>
)} */}
                </div>
                {/* <button onClick={Logout}>logout</button> */}
              </div>
            </div>
          </div>
        </form>

        {open && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none -mt-6 border border-grey-100">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-[#E4E4E4] outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex justify-end p-1">
                    <button
                      onClick={() => setOpen(false)}
                      type="button"
                      className="text-red-600 bg-transparent hover:bg-gray-200 rounded-lg text-lg p-1 ml-auto inline-flex items-center"
                      data-modal-toggle="log-in-model"
                    >
                      <HiOutlineX className="w-6 h-6" />
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative px-4 pb-4">
                    <div className="p-6 pt-0 ">
                      <form onSubmit={handleLoginSubmit(handleLogin)}>
                        <div className="font-display flex flex-col justify-center items-center">
                          <h2 className="my-2  font-bold text-secondary text-4xl text-gray-700 text-center">
                            ይህ ኢጵላሣጵ የመገበያያ አውድ ነው
                          </h2>
                          <div className="inline-grid grid-cols-2 gap-x-4">
                            <div>
                              <img src={product3} />
                            </div>
                            <div className="">
                              <div className="mb-4 w-full">
                                <span className="block text-lg font-display text-inputColor">
                                  ኢሜል
                                </span>
                                <input
                                  type="text"
                                  {...registerLogin("email", {
                                    required: true,
                                    pattern:
                                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                  })}
                                  className="form-control block w-full px-4 py-2 text-base text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none"
                                />
                                {loginErrors.email && (
                                  <p className="text-red-500">
                                    ያስገቡት ኢሜል ትክክል መሆኑን ያረጋግጡ
                                  </p>
                                )}
                              </div>
                              <div className="mb-2 w-full">
                                <span className="block text-lg font-display text-inputColor">
                                  የይለፍ ቃል
                                </span>
                                <input
                                  type="password"
                                  {...registerLogin("password", {
                                    required: true,
                                    maxLength: 15,
                                  })}
                                  className="form-control block w-full px-4 py-2 text-base text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                />
                                {loginErrors.password && (
                                  <p className="text-red-500">
                                    ያስገቡት የይለፍ ቃል ትክክል መሆኑን ያረጋግጡ
                                  </p>
                                )}
                              </div>

                              {loading ? (
                                <button
                                  disabled
                                  className="inline-block px-2 xl:py-2 py-2 mt-6 bg-primary text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-primary hover:shadow-lg focus:bg-primary focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary active:shadow-lg transition duration-150 ease-in-out w-full"
                                  data-mdb-ripple="true"
                                  data-mdb-ripple-color="light"
                                >
                                  <svg
                                    aria-hidden="true"
                                    role="status"
                                    class="inline w-4 h-4 mr-3 text-white animate-spin"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="#E5E7EB"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                  loading...
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="inline-block px-2 py-1 mt-6 bg-primary text-white font-medium text-lg leading-snug uppercase rounded shadow-md hover:bg-primary hover:shadow-lg focus:bg-primary focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary active:shadow-lg transition duration-150 ease-in-out w-full"
                                  data-mdb-ripple="true"
                                  data-mdb-ripple-color="light"
                                  // onClick ={ () => handleGetOrder(products.id)}
                                >
                                  ግብት
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};
export default ProductLike;
