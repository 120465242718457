// react
import React, { useEffect } from "react";
import Layout from "../../layout/layout";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { viewManufacture } from "../../../actions/manufactureAction";
import { dataProducts } from "../data";
import { useState } from "react";
import Loading from "../loading";
import defualtImg from "../../../img/logobg1.png";
import Product_detail from "../products/product_detail";
import ProductModel from "../products/popup-model";
import Loginform from "../../users/login";
import Favorite from "../products/favorite";

const Home = () => {
  // dispatch
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();
  // hooks
  // const { loading, manufacture } = useSelector((state) => state.manufacture);
  const { manufacture } = useSelector((state) => state.manufacture);
  // use effect
  useEffect(() => {
    dispatch(viewManufacture());
  }, []);
  const [like, setLike] = useState(false);
  const handeleLike = () => {
    if (!like) {
      setLike(true);
    } else {
      setLike(false);
    }
  };

  const handeleRate = (id) => {
    // console.log("manufacture Id is :", id);
    navigate("/manufacture/rate/" + id);
  };

  return (
    // <>
    <Layout>
      <div className="bg-[#E3E6E6]">
        {/* css like product pages */}
        <div className="md:pt-0 lg:pt-0 pt-12 pb-6">
          <Product_detail />
        </div>

        <div className="w-11/12 xl:w-11/12 mx-auto">
          <section className="mb-12 text-gray-800 text-center ">
            <div class="grid lg:grid-cols-4 xl:gap-10 md:gap-6 xl:gap-x-4">
              {dataProducts?.length > 0 ? (
                dataProducts?.slice(0, 8).map((manufacture, index) => (
                  <div key={index} className="mb-6 lg:mb-0">
                    <div class="relative group block bg-white overflow-hidden rounded-lg shadow-inner shadow-blue-950/40 p-1">
                      <div className="flex pb-2">
                        <div
                          className="p-2 relative overflow-hidden bg-no-repeat bg-cover relative overflow-hidden bg-no-repeat bg-cover rounded-lg"
                          data-mdb-ripple="true"
                          data-mdb-ripple-color="light"
                        >
                          <img
                            className="w-screen h-52 transition cursor-pointer duration-700"
                            src={manufacture?.linkImg}
                            alt="product img not found"
                          />
                        </div>
                      </div>
                      <div className="relative mt-0 bg-[#E3E6E6]  transition-all before:absolute before:-mt-[44px] before:transition-all group-hover:-mt-[44px] before:group-hover:-mt-[44px]  duration-1000">
                        {/* <div class="relative -top-4 bg-gray-800 py-2.5 px-5 font-medium uppercase text-white transition-all before:absolute before:top-1  before:h-full before:w-full before:transition-all hover:top-0 before:hover:top-0  duration-700"> */}

                        <p className="text-sm mt-8 font-bold">
                          {manufacture?.name}
                        </p>
                        <p className="mb-2 text-sm ">
                          {manufacture.description.substring(0, 130) + " ..."}
                        </p>

                        <div className="m-auto w-full pb-2 transition-[1s] duration-1000 -bottom-12 relative  group-hover:bottom-0">
                          {/* <div className=" before:bg-gray-800 bg-transparent before:-z-100 before:absolute before:left-0 before:top-0 pb-2 before:w-full before:origin-top-left before:scale-x-0  before:transition-transform before:duration-300 before:content-[''] before:hover:scale-x-100"> */}
                          <div className="flex justify-center">
                            {userInfo ? (
                              <button className="relative flex p-2 px-12 bg-zinc-950 hover:bg-[#0397FF] text-sm text-white cursor-pointer hover:text-black duration-700">
                                open
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  setShowLogin(true);
                                }}
                                className="relative flex p-2 px-12 bg-zinc-950 hover:bg-[#0397FF] text-sm text-white cursor-pointer hover:text-black duration-700"
                              >
                                open
                              </button>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* product secfication buttom*/}
                      <div className="group-hover:block absolute bottom-7 -translate-y-0 right-2 group-hover:top-2 -top-12 text-sm rounded-sm cursor-pointer text-black rounded-tr-xl  opacity-70 transition-[1s] duration-1000">
                        <div className="relative">
                          <div className="pt-1">
                            <button
                              className="group block bg-[#E3E6E6] hover:bg-[#0397FF] p-2 rounded-full hover:rotate-180 duration-1000"
                              // onClick={() => handleGetOrder(products.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-5 h-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                />
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="group-hover:block absolute bottom-7 -translate-y-0 left-2 group-hover:top-2 -top-12 text-sm rounded-sm cursor-pointer text-black rounded-tr-xl  opacity-70 transition-[1s] duration-1000">
                        <div className="relative">
                          <div className="pt-1">
                            {userInfo ? (
                              <Favorite productSLike={manufacture} />
                            ) : (
                              <button
                                className="bg-[#E3E6E6] hover:bg-[#0397FF] duration-700 p-2 rounded-full hover:rotate-45 rotate-duration-1000"
                                onClick={() => {
                                  setShowLogin(true);
                                }}
                                data-te-toggle="tooltip"
                                data-te-placement="top"
                                data-te-ripple-color="light"
                                title="like"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-5 h-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                                  />
                                </svg>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>
                  <Loading />
                </div>
              )}
            </div>
          </section>
        </div>
        <ProductModel isVisible={showLogin} onClose={() => setShowLogin(false)}>
          <Loginform />
        </ProductModel>
      </div>
    </Layout>
  );
};
export default Home;
