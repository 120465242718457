import React, { Fragment, useState, useEffect, useRef } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { HiOutlineX } from "react-icons/hi";
import TopHeader from "./top_header";
import team from "../../img/icon/team-management.png";
import payment from "../../img/icon/payment-method.png";
import discount from "../../img/icon/discount.png";
import education from "../../img/icon/puzzle.png";
import healthcare from "../../img/icon/healthcare.png";
import colorPallet from "../../img/icon/color-palette.png";
import building from "../../img/icon/building.png";
import trade from "../../img/icon/trade.png";
import compliant from "../../img/icon/compliant.png";
import software from "../../img/icon/software-engineer.png";
import custemer from "../../img/icon/customer-service.png";
import vihecles from "../../img/icon/vehicles.png";
import shield from "../../img/icon/shield.png";
import hand from "../../img/icon/hand.png";
import flight from "../../img/icon/flight.png";
import defaultPic from "../../img/screenb.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BiCartDownload } from "react-icons/bi";
import { CgMenuGridR } from "react-icons/cg";
import logo from "../../img/logobg1.png";
import logo2 from "../../img/logobg2.png";
import AddressBaseUrl from "../../utils/BaseUrl";
import { AiFillDelete } from "react-icons/ai";
import Popup_catrs from "../pages/products/popupcarts";
import { dataProducts } from "../pages/data";

import {
  getCartTotal,
  removeFromCarts,
  decreaseItemQuantity,
  increaseItemQuantity,
} from "../../actions/cartAction";

const Header = () => {
  const { carts, totalQuantity, totalPrice } = useSelector(
    (state) => state.carts
  );

  const { product } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCartTotal());
  }, [carts]);

  // use state
  const [navBackground, setNavBackground] = useState(false);
  const [nav, setNav] = useState(false);
  const [open, setOpen] = useState(false);
  const [feature, setFeature] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);

  // get user
  const user = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  const navRef = useRef();
  const location = useLocation();
  // const navigate = useNavigate();

  navRef.current = navBackground;

  // use effect
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 96;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const logout = () => {
  //   localStorage.removeItem("userInfo");
  //   navigate("/");
  //   window.location.reload();
  // };

  // styles
  // const navLinkStyles = ({ isActive }) => {
  //   return {
  //     color: isActive ? "#F3AE33" : navBackground ? "#2b2b2b" : "#2b2b2b",
  //   };
  // };
  // const linkColor =
  //   "px-5 py-2 flex items-center leading-snug font-display hover:opacity-75 text-lg hover:transform hover:scale-100 hover:md:scale-125 hover:duration-300";

  const styles = () => {
    switch (location.pathname) {
      case "/":
        return navBackground ? "#151511" : "#151511";
      case "/about":
        return navBackground ? "#1C1C23" : "#1C1C23";
      default:
        return navBackground ? "#151511" : "#151511";
    }
  };

  // logo
  const logos = () => {
    switch (location.pathname) {
      case "/":
        return (
          <img
            className="xl:w-48 md:w-40 w-32"
            src={navBackground ? logo : logo}
            alt="Logo"
          />
        );
      default:
        return (
          <img
            className="xl:w-48 md:w-40 w-32"
            src={navBackground ? logo : logo}
            alt="Logo"
          />
        );
    }
  };

  const handleNav = () => {
    setNav((prev) => !prev);
  };

  const handelOpen = () => {
    if (!open) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  const handelFeature = () => {
    if (!feature) {
      setFeature(true);
    } else {
      setFeature(false);
    }
  };

  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = dataProducts?.filter((value) => {
      return value.name.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  return (
    <>
      <div
        style={{
          background: styles(),
          position: navBackground ? "fixed" : "relative",
          boxShadow: navBackground
            ? "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            : "0 0 #0000",
        }}
        className="xl:bg-white right-0 left-0 z-10 border-none"
        p="md"
      >
        {navBackground ? null : <TopHeader />}

        {navBackground ? (
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <div className="container flex justify-between items-center mx-auto text-white bg-[#1C1C23]">
              <ul className="md:flex">
                <div className=" flex  py-1">
                  <div className="flex h-10 w-screen">
                    <div className="ml-0 w-24 md:w-48 lg:w-52 md:ml-12">
                      <ul className="">
                        <li className="text-xl">
                          {/* <h1>m</h1> */}
                          <NavLink to="#">
                            <button
                              onClick={handelOpen}
                              type="button"
                              className="flex form-select text-white "
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="white"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="white"
                                class="w-8 h-8"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                                />
                              </svg>
                              Category
                              {open ? (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5 m-2"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M4.5 15.75l7.5-7.5 7.5 7.5"
                                    />
                                  </svg>
                                </>
                              ) : (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5 m-2"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                    />
                                  </svg>
                                </>
                              )}
                            </button>
                            {open ? (
                              <>
                                <ul className="space-y-5 md:space-y-2 lg:space-y-2 pt-4 w-96 md:w-52 lg:w-52 bg-[#E4E4E4] px-4 md:-ml-12 ml-1">
                                  <li className=" bg-[#E3E6E6] rounded-lg shadow-inner shadow-blue-950/10 h-8 flex space-x-2">
                                    <div class="">
                                      <img
                                        className="h-5 w-5 ml-5"
                                        src={trade}
                                      />
                                    </div>
                                    <div class="text-md text-black ">
                                      <NavLink to="/productCategory/cloth">
                                        cloth
                                      </NavLink>
                                    </div>
                                  </li>
                                  <li className=" bg-[#E3E6E6] rounded-lg shadow-inner shadow-blue-950/10 h-8 flex space-x-2">
                                    <div class="">
                                      <img
                                        className="h-5 w-5 ml-4"
                                        src={education}
                                      />
                                    </div>
                                    <div class=" text-md text-black">
                                      <NavLink to="/productCategory/electronics">
                                        electronics
                                      </NavLink>
                                    </div>
                                  </li>
                                  <li className=" bg-[#E3E6E6] rounded-lg shadow-inner shadow-blue-950/10 h-8 flex space-x-2">
                                    <div class="">
                                      <img
                                        className="h-5 w-5 ml-5"
                                        src={healthcare}
                                      />
                                    </div>
                                    <div class="text-md text-black">
                                      <NavLink to="/productCategory/machinary">
                                        machinary
                                      </NavLink>
                                    </div>
                                  </li>
                                  <li className=" bg-[#E3E6E6] rounded-lg shadow-inner shadow-blue-950/10 h-8 flex space-x-2">
                                    <div class="">
                                      <img
                                        className="h-5 w-5 ml-5"
                                        src={colorPallet}
                                      />
                                    </div>
                                    <div class="text-md text-black ">
                                      <NavLink to="/productCategory/softawre">
                                        softawre
                                      </NavLink>
                                    </div>
                                  </li>
                                  <li className=" bg-[#E3E6E6] rounded-lg shadow-inner shadow-blue-950/10 h-8 flex space-x-2">
                                    <div class="">
                                      <img
                                        className="h-5 w-5 ml-5"
                                        src={building}
                                      />
                                    </div>
                                    <div class=" text-md text-black">
                                      <button onClick={handelFeature}>
                                        education
                                      </button>
                                    </div>
                                  </li>
                                  <li className=" bg-[#E3E6E6] rounded-lg shadow-inner shadow-blue-950/10 h-8 flex space-x-2">
                                    <div class="">
                                      <img
                                        className="h-5 w-5 ml-5"
                                        src={vihecles}
                                      />
                                    </div>
                                    <div class="text-md text-black">
                                      <button onClick={handelFeature}>
                                        vihecles
                                      </button>
                                    </div>
                                  </li>
                                </ul>
                              </>
                            ) : (
                              <div
                                className="relative overflow-hidden bg-no-repeat bg-cover relative overflow-hidden bg-no-repeat bg-cover shadow-lg rounded-lg w-40 z-0"
                                data-mdb-ripple="true"
                                data-mdb-ripple-color="light"
                              ></div>
                            )}
                          </NavLink>
                        </li>
                      </ul>
                    </div>

                    <div class="relative flex w-full">
                      <form onSubmit={handleFilter}>
                        <div class=" flex w-full items-stretch z-10">
                          <input
                            type="search"
                            class=" lg:w-[800px] md:w-[800px] w-[150px] rounded-r border-b-[1px] border-b-gray-500 bg-[#151511] text-white px-4 py-1.5 active:outline-none focus-visible:outline-[#0397FF] outline-none focus-visible:border-b-transparent duration-300 resize-none"
                            placeholder="please search products"
                            aria-label="Search"
                            aria-describedby="button-addon1"
                            // value={term}
                            // onChange={(e) => setTerm(e.target.value)}
                            value={wordEntered}
                            onChange={handleFilter}
                          />
                          <button
                            class="relative flex h-full right-0 items-center rounded-r md:px-6 px-0 py-1.5 bg-[#060607] text-white tracking-wider uppercase duration-300 hover:border-[1px] hover:border-[#0397FF] border-transparent"
                            type="submit"
                            data-te-ripple-init
                            data-te-ripple-color="light"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              class="h-3 w-3 md:h-5 md:w-5"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            search
                          </button>
                        </div>
                        <div>
                          {filteredData.length != 0 && (
                            <div className="bg-white divide-y mt-2 divide-gray-100 rounded-lg shadow w-72 dark:bg-gray-700 dark:divide-gray-600">
                              {filteredData.slice(0, 10).map((value, key) => {
                                return (
                                  <>
                                    <div className="flex ml-2">
                                      <img
                                        className="h-8 w-8"
                                        // src={`${AddressBaseUrl}/images/${value.featureImage}`}
                                        src={value?.linkImg}
                                      />
                                      <a
                                        className="pl-2"
                                        href={`/searchProduct/${value.categoryName}`}
                                      >
                                        <p
                                          className="text-black text-center cursor-pointer"
                                          type="submit"
                                        >
                                          {value.name}{" "}
                                        </p>
                                      </a>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </form>
                    </div>

                    <div className=" absolute items-center right-1 md:right-12 lg:right-12">
                      <button
                        className="text-white flex"
                        onClick={() => setCartOpen(true)}
                      >
                        <BiCartDownload className="w-6 h-6 md:w-8 lg:w-8 md:h-8 lg:h-8" />
                        <p>[{totalQuantity}]</p>
                      </button>
                    </div>

                    {cartOpen && (
                      <>
                        <Popup_catrs
                          isVisible={cartOpen}
                          onClose={() => setCartOpen(false)}
                        />
                      </>
                    )}
                  </div>
                </div>
              </ul>
            </div>
          </div>
        ) : (
          <div
            className="pl-3"
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <div className="container flex justify-between items-center mx-auto px-3 text-white">
              <div className="md:hidden flex">
                <div className="flex bg-[#1C1C23] justify-between items-center fixed md:relative left-0 top-0 w-full">
                  <div className="flex ml-4">
                    <NavLink to="/" className="flex">
                      <img src={logo} className="w-12 h-12" />
                      <span className="p-2 font-serif"> login </span>
                    </NavLink>
                  </div>
                  <div
                    onClick={handleNav}
                    className="block md:hidden mr-4 text-textColor"
                  >
                    {!nav && <CgMenuGridR size={24} className="h-8 w-8" />}
                  </div>
                </div>
              </div>
              <ul className="hidden md:flex">
                <div className=" flex  py-1">
                  <div className=" md:flex grid md:grid-cols-4 grid-cols-2 md:gap-10 gap-2 h-12">
                    <div className="md:ml-6">
                      <ul className="pt-2">
                        <li className="text-xl">
                          <NavLink to="#">
                            <button
                              onClick={handelOpen}
                              type="button"
                              className="flex form-select text-white font-serif"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="white"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="white"
                                class="w-8 h-8"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                                />
                              </svg>
                              Category
                              {open ? (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5 m-2"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M4.5 15.75l7.5-7.5 7.5 7.5"
                                    />
                                  </svg>
                                </>
                              ) : (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5 m-2 "
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                    />
                                  </svg>
                                </>
                              )}
                            </button>
                            {/* <option onClick={() => setOpen(true)}>ዘርፍ
                          </option> */}
                            {open ? (
                              <>
                                <ul className="space-y-2 pt-4 w-52 bg-[#E4E4E4] px-4 md:-ml-12 ml-1">
                                  <li className=" bg-[#E3E6E6] rounded-lg shadow-inner shadow-blue-950/10 h-8 flex space-x-2">
                                    <div class="">
                                      <img
                                        className="h-5 w-5 ml-5"
                                        src={trade}
                                      />
                                    </div>
                                    <div class="text-md text-black font-serif">
                                      <NavLink to="/productCategory/cloth">
                                        cloth
                                      </NavLink>
                                    </div>
                                  </li>
                                  <li className=" bg-[#E3E6E6] rounded-lg shadow-inner shadow-blue-950/10 h-8 flex space-x-2">
                                    <div class="">
                                      <img
                                        className="h-5 w-5 ml-4"
                                        src={education}
                                      />
                                    </div>
                                    <div class=" text-md text-black font-serif">
                                      <NavLink to="/productCategory/electronics">
                                        electronics
                                      </NavLink>
                                    </div>
                                  </li>
                                  <li className=" bg-[#E3E6E6] rounded-lg shadow-inner shadow-blue-950/10 h-8 flex space-x-2">
                                    <div class="">
                                      <img
                                        className="h-5 w-5 ml-5"
                                        src={healthcare}
                                      />
                                    </div>
                                    <div class="text-md text-black font-serif">
                                      <NavLink to="/productCategory/machinary">
                                        machinary
                                      </NavLink>
                                    </div>
                                  </li>
                                  <li className=" bg-[#E3E6E6] rounded-lg shadow-inner shadow-blue-950/10 h-8 flex space-x-2">
                                    <div class="">
                                      <img
                                        className="h-5 w-5 ml-5"
                                        src={colorPallet}
                                      />
                                    </div>
                                    <div class="text-md text-black font-serif ">
                                      <NavLink to="/productCategory/softawre">
                                        softawre
                                      </NavLink>
                                    </div>
                                  </li>
                                  <li className=" bg-[#E3E6E6] rounded-lg shadow-inner shadow-blue-950/10 h-8 flex space-x-2">
                                    <div class="">
                                      <img
                                        className="h-5 w-5 ml-5"
                                        src={building}
                                      />
                                    </div>
                                    <div class=" text-md text-black font-serif">
                                      <button onClick={handelFeature}>
                                        education
                                      </button>
                                    </div>
                                  </li>
                                  <li className=" bg-[#E3E6E6] rounded-lg shadow-inner shadow-blue-950/10 h-8 flex space-x-2">
                                    <div class="">
                                      <img
                                        className="h-5 w-5 ml-5"
                                        src={custemer}
                                      />
                                    </div>
                                    <div class="text-md text-black font-serif">
                                      <button onClick={handelFeature}>
                                        vihecles
                                      </button>
                                    </div>
                                  </li>
                                </ul>
                              </>
                            ) : (
                              <div
                                className=" relative overflow-hidden bg-no-repeat bg-cover shadow-lg rounded-lg w-40 z-0"
                                data-mdb-ripple="true"
                                data-mdb-ripple-color="light"
                              ></div>
                            )}
                          </NavLink>
                        </li>
                      </ul>
                    </div>

                    <div className="hidden md:flex">
                      <ul className="flex text-sm gap-6 rounded-lg text-white cursor-pointer pt-2">
                        <li className="text-lg pl-1 pr-1">
                          <NavLink
                            to="https://address.eplusapp.et/main"
                            onClick={handelFeature}
                            className="flex"
                          >
                            today
                          </NavLink>
                        </li>
                        <li className="text-lg pl-1 pr-1 font-serif">
                          <NavLink to="/" className="flex">
                            New
                          </NavLink>
                        </li>
                        <li className="text-lg pl-1 pr-1 font-serif">
                          <NavLink
                            to="https://address.eplusapp.et/main"
                            className="flex"
                          >
                            sell
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {feature ? (
                  <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none -mt-6 border border-grey-100">
                      <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-[#E4E4E4] outline-none focus:outline-none">
                          <div className="flex justify-end z-20 -mb-8">
                            <button
                              onClick={() => setFeature(false)}
                              type="button"
                              className="text-red-600 bg-transparent hover:bg-gray-200 rounded-lg text-lg p-1 ml-auto inline-flex items-center"
                              data-modal-toggle="log-in-model"
                            >
                              <HiOutlineX className="w-6 h-6" />
                            </button>
                          </div>
                          <div
                            className="relative overflow-hidden bg-no-repeat bg-cover relative overflow-hidden bg-no-repeat bg-cover shadow-lg rounded-lg"
                            data-mdb-ripple="true"
                            data-mdb-ripple-color="light"
                          >
                            <img
                              className="w-screen h rounded-xl bg-center bg-cover duration-500"
                              src={defaultPic}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <h1>{null}</h1>
                )}
              </ul>

              <ul
                className={
                  nav
                    ? "xl:hidden fixed left-0 top-0 w-full text-textColor bg-[#334155] ease-in-out duration-500 hover:opacity-75 text-lg hover:transform hover:scale-100 hover:md:scale-125 hover:duration-300 space-y-3 pb-24"
                    : "ease-in-out duration-500 fixed left-[-100%]"
                }
              >
                <div className="flex items-center justify-between pb-6">
                  <h1>
                    <img className="w-20 ml-4" src={logo2} alt="Logo" />
                  </h1>
                  <h1 onClick={handleNav} className="mr-4">
                    {" "}
                    {nav && <HiOutlineX size={20} className="" />}
                  </h1>
                </div>
                <li className="p-2 bg-[#475569] rounded-lg shadow-inner w-6/12">
                  <a
                    className="leading-snug hover:text-primary font-display  hover:opacity-75 font-serif"
                    href="https://address.eplusapp.et/"
                  >
                    Digital Address
                  </a>
                </li>
                <li className="p-2 bg-[#475569] rounded-lg shadow-inner w-6/12">
                  <a
                    className="leading-snug hover:text-black font-display  hover:opacity-75 font-serif"
                    href="https://eplusapp.et/"
                  >
                    Digital Promotion
                  </a>
                </li>
                <li className="p-2 bg-[#475569] rounded-lg shadow-inner w-6/12">
                  <a
                    className="leading-snug hover:text-black font-display  hover:opacity-75 font-serif"
                    href="/"
                  >
                    Ecommerce
                  </a>
                </li>
                <li className="p-2 bg-[#475569] rounded-lg shadow-inner w-6/12">
                  <a
                    className="leading-snug hover:text-primary font-display  hover:opacity-75 font-serif"
                    href="https://stock.eplusapp.et/"
                  >
                    Stock Market
                  </a>
                </li>
                <li className="p-2 bg-[#475569] rounded-lg shadow-inner w-6/12">
                  <a
                    className="leading-snug hover:text-primary font-display  hover:opacity-75 font-serif"
                    href="/"
                  >
                    today
                  </a>
                </li>
                <li className="p-2 bg-[#475569] rounded-lg shadow-inner w-6/12">
                  <a
                    className="leading-snug hover:text-primary font-display  hover:opacity-75 font-serif"
                    href="/"
                  >
                    new
                  </a>
                </li>
                <li className="p-2 bg-[#475569] rounded-lg shadow-inner w-6/12">
                  <a
                    className="leading-snug hover:text-primary font-display  hover:opacity-75 font-serif"
                    href="https://address.eplusapp.et/"
                  >
                    sell
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
