import { createSlice } from "@reduxjs/toolkit";
import { getProductDetail } from "../actions/productDetailAction";

const initialState = {
  loading: false,
  error: false,
  success: false,
  message: null,
  productDetail: [],
};

const productDetailSlice = createSlice({
  name: "productDetail",
  initialState,
  reducers: {},
  extraReducers: {
    // add product likes
    [getProductDetail.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getProductDetail.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.productDetail = payload;
    },
    [getProductDetail.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

// export
export default productDetailSlice.reducer;
