// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../utils/toast";

export const viewManufacture = createAsyncThunk(
  "manufacture",
  async (thunkAPI) => {
    try {
      let URL = `http://192.168.1.33:11215/addressapi/organizations/all`;
      // let response = await createContact(message);
      let response = await axios.get(URL);
      //  console.log(response);
      if (response.status === 200) {
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      //console.log("Error", e.response.data);
      handleError("loading...");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getManufacture = createAsyncThunk(
  "manufacture",
  async (id, thunkAPI) => {
    try {
      // console.log("product action id", id);
      let URL = `https://address-backend.eplusapp.et/addressapi/organizations/${id}`;
      // let response = await createContact(message);
      let response = await axios.get(URL);
      //  console.log(response);
      if (response.status === 200) {
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      //console.log("Error", e.response.data);
      //handleError("loading...");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const searchManufacture = createAsyncThunk(
  "manufacture",
  async (Mname, thunkAPI) => {
    try {
      // base url
      // console.log("manufacture action page", Mname);

      let baseUrl = `https://address-backend.eplusapp.et/addressapi/organizations/search?key=${Mname}`;
      // response
      let response = await axios.get(baseUrl);
      // let response = await createContact(message);
      if (response.status === 200) {
        //console.log(response.data);
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      // console.log("Error", e.response.data);
      handleError("search single Manufacture...");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
