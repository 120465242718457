export const dataProducts = [
  {
    id: 1,
    name: "Mario Kart™ 8 Deluxe",
    price: 59.99,
    description: "Nintendo Switch",
    quality: 1,
    categoryName: "cloth",
    linkImg: "/images/tishert/download-1.jpg",
    galleryImg: [
      {
        img: "/images/tishert/download-1.jpg",
      },
      {
        img: "/images/tishert/download-2.jpg",
      },
      {
        img: "/images/tishert/download-3.jpg",
      },
      {
        img: "/images/tishert/download-4.jpg",
      },
    ],
  },

  {
    id: 2,
    name: "TRIANGLE STRATEGY™",
    price: 24.99,
    description: "Nintendo Switch",
    quality: 1,
    categoryName: "electronics",
    linkImg: "/images/computerimge/OIP-3.jpg",
    galleryImg: [
      {
        img: "/images/computerimge/OIP-3.jpg",
      },
      {
        img: "/images/computerimge/OIP-4.jpg",
      },
      {
        img: "/images/computerimge/OIP-6.jpg",
      },
      {
        img: "/images/computerimge/OIP-7.jpg",
      },
    ],
  },
  {
    id: 3,
    name: "Pokémon™ Legends: Arceus",
    price: 71.08,
    description: "Nintendo Switch",
    categoryName: "machinary",
    quality: 1,
    linkImg: "/images/computerimge/OIP-16.jpg",
    galleryImg: [
      {
        img: "/images/computerimge/OIP-16.jpg",
      },
      {
        img: "/images/computerimge/desctops.jpg",
      },
      {
        img: "/images/computerimge/OIP-1.jpg",
      },
      {
        img: "/images/computerimge/OIP-2.jpg",
      },
    ],
  },
  {
    id: 4,
    name: "Super Mario™ 3D World + Bowser’s Fury",
    price: 90.12,
    description: "Nintendo Switch",
    quality: 1,
    categoryName: "softawre",
    linkImg: "/images/computerimge/OIP-17.jpg",
    galleryImg: [
      {
        img: "/images/computerimge/OIP-17.jpg",
      },
      {
        img: "/images/computerimge/OIP-18.jpg",
      },
      {
        img: "/images/computerimge/OIP-19.jpg",
      },
      {
        img: "/images/computerimge/OIP-20.jpg",
      },
    ],
  },
  {
    id: 5,
    name: "Cuphead",
    price: 19.21,
    description: "Nintendo Switch",
    quality: 1,
    categoryName: "electronics",
    linkImg: "/images/computerimge/OIP-21.jpg",
    galleryImg: [
      {
        img: "/images/computerimge/OIP-21.jpg",
      },
      {
        img: "/images/computerimge/OIP-22.jpg",
      },
      {
        img: "/images/computerimge/OIP-23.jpg",
      },
      {
        img: "/images/computerimge/OIP-24.jpg",
      },
    ],
  },
  {
    id: 6,
    name: "Minecraft",
    price: 29.46,
    description: "Nintendo Switch",
    quality: 1,
    categoryName: "cloth",
    linkImg: "/images/tishert/th-1.jpg",
    galleryImg: [
      {
        img: "/images/tishert/th-1.jpg",
      },
      {
        img: "/images/tishert/th-2.jpg",
      },
      {
        img: "/images/tishert/th-3.jpg",
      },
      {
        img: "/images/tishert/th-4.jpg",
      },
    ],
  },
  {
    id: 7,
    name: "Mario + Rabbids® Kingdom Battle",
    price: 31.06,
    description: "Nintendo Switch",
    quality: 1,
    categoryName: "machinary",
    linkImg: "/images/computerimge/OIP-30.jpg",
    galleryImg: [
      {
        img: "/images/computerimge/OIP-30.jpg",
      },
      {
        img: "/images/computerimge/OIP-31.jpg",
      },
      {
        img: "/images/computerimge/OIP-32.jpg",
      },
      {
        img: "/images/computerimge/OIP-33.jpg",
      },
    ],
  },
  {
    id: 8,
    name: "Unravel Two",
    price: 17.82,
    description: "Nintendo Switch",
    quality: 1,
    categoryName: "cloth",
    sale: 63, //percent
    linkImg: "/images/computerimge/OIP-40.jpg",
    galleryImg: [
      {
        img: "/images/computerimge/OIP-40.jpg",
      },
      {
        img: "/images/computerimge/OIP-41.jpg",
      },
      {
        img: "/images/computerimge/OIP-42.jpg",
      },
      {
        img: "/images/computerimge/OIP-43.jpg",
      },
    ],
  },
];
