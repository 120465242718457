import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/layout";
import { getProduct } from "../../../actions/searchProductAction";
import { viewProductRate } from "../../../actions/productRateAction";
import AddressBaseUrl from "../../../utils/BaseUrl";
import web from "../../../img/bossweb.png";
import defualtImg from "../../../img/2748558.png";
import { FaStar } from "react-icons/fa";
import ProductModel from "./popup-model";
import Loginform from "../../users/login";
import Product_rate from "./product_rate";
import {
  addProductLike,
  viewProductLike,
} from "../../../actions/productLikeAction";
import Pay from "../payment/Pay";
import Favorite from "./favorite";
import { dataProducts } from "../data";

const Orders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [showLogin, setShowLogin] = useState(false);
  const [activeImg, setActiveImage] = useState();
  const [showModal, setShowModal] = useState(false);

  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const { id } = useParams();

  useEffect(() => {
    dispatch(getProduct(id));
  }, []);
  useEffect(() => {
    dispatch(viewProductLike(id));
  }, []);
  const { productLike } = useSelector((state) => state.productLike);

  //const { product } = useSelector((state) => state.product);
  // console.log("product category : ", product)
  useEffect(() => {
    dispatch(viewProductRate(id));
  }, []);
  const { productRate } = useSelector((state) => state.productRate);
  const rateIndex = productRate.length;

  let Total = 0;
  let newData1 = Array.prototype.map.call(productRate, (item) => {
    Total = +Total + +item.rating;
  });

  const totalRating = Total / rateIndex;
  //  console.log('rate : ', totalRating);

  const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9",
  };

  const styles = {
    stars: {
      display: "flex",
      flexDirection: "row",
    },
  };
  const stars = Array(5).fill(0);
  const handleGetRate = (id) => {
    if (id !== null) {
      dispatch(getProduct(id));
      dispatch(viewProductRate(id));
      setShowModal(true);
    }
  };
  const decreamentQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevCount) => prevCount - 1);
    }
  };
  const increamentQuantity = () => {
    if (quantity < 100) {
      setQuantity((prevCount) => prevCount + 1);
      // setTotal(prevCount=>prevCount+(price));
    }
  };

  //statik data
  const product = dataProducts.find((product) => product.id === parseInt(id));
  console.log("single product : ", product);
  return (
    <Layout>
      <div className="bg-[#E3E6E6] pt-12 md:pt-0 lg:pt-0 pb-6 md:pb-12 lg:pb-12">
        <div className="">
          <div className="flex flex-col justify-between lg:flex-row gap-6 lg:items-center  bg-white">
            <div className="flex flex-col gap-4 lg:w-4/12">
              <div className="relative overflow-hidden bg-no-repeat bg-cover shadow-lg rounded-lg my-2 h-80 w-full">
                {/* {product?.length > 0
                  ? product?.map((images, index) => (
                      <div className="">
                        <img
                          src={
                            activeImg ||
                            `${AddressBaseUrl}/images/${images?.imageName}`
                          }
                          alt=""
                          className=" w-full h-80"
                        />
                      </div>
                    ))
                  : null} */}
                <img
                  src={activeImg || product?.linkImg}
                  alt=""
                  className=" w-full h-80"
                />
              </div>
              <div className="flex justify-between h-24">
                {product?.galleryImg?.length > 0 ? (
                  product?.galleryImg?.slice(0, 4).map((images, index) => (
                    <div>
                      <img
                        // src={`${AddressBaseUrl}/images/${images?.imageName}`}
                        src={images?.img}
                        alt=""
                        className="w-24 h-24 rounded-md cursor-pointer"
                        onClick={() => setActiveImage(`${images?.img}`)}
                      />
                    </div>
                  ))
                ) : (
                  <img
                    src={defualtImg}
                    onClick={() => setActiveImage(defualtImg)}
                    className="w-24 h-24 rounded-md cursor-pointer"
                  />
                )}
              </div>
            </div>

            {/* View product detials */}
            <div className="flex flex-col gap-2 lg:w-5/12">
              <div>
                <span className=" text-violet-600 font-semibold">
                  {product?.name}
                </span>
                <h1 className="text-3xl font-bold">{product?.brand}</h1>
              </div>
              <p className="text-gray-700">
                {product?.description}
                {/* ኢጵላሣጵ አዲስ አሰራር [እይት]፣ [ትኩርና] ዲጂታል የንግድ ተቋም [ምስርት] ነው። ኢጵላሣጵ ለመረጃ
                አጠቃቀም፣ ደኅንነት፣ ልውውጥና ክምችት(አደልክ) የሚረዱ መጥቀቶችን ወይም ሙያዊ አገልግሎቶችን
                [ፍልግ]፣ [ንድፍ]፣ [ፍብርክ]፣ [ሙክር]፣ [ጥግንና] ከመደብ ተግዳሮቶች በላይ ትግብር ነው።
                አወንታዊ ተፅኖ ፍጥር፣ ለተቋማት የቴክኖሎጂ መፍትሄዎችን ብልጽግ፤ በሰፊ የፈጠራ አቀራረብ ዲጂታል
                ጉጉትን ግኝት፣ አገር በቀል ብራንድ ወይም መለዮ ፍጥር ነው። */}
              </p>

              <div style={styles.stars}>
                {stars.map((_, index) => {
                  return (
                    <FaStar
                      key={index}
                      size={20}
                      onClick={() => handleGetRate(product?.id)}
                      color={totalRating > index ? colors.orange : colors.grey}
                      style={{
                        marginRight: 5,
                        cursor: "pointer",
                      }}
                      values={totalRating}
                    />
                  );
                })}
                <h1>({rateIndex})</h1>
              </div>

              <div className="flex flex-row items-center">
                <button
                  type="button"
                  onClick={decreamentQuantity}
                  className="bg-gray-200 py-2 px-5 rounded-lg text-violet-800 text-xl"
                >
                  {" "}
                  -{" "}
                </button>
                <div className="py-4 px-6 rounded-lg">{quantity}</div>
                <button
                  type="button"
                  onClick={increamentQuantity}
                  className="bg-gray-200 py-2 px-4 rounded-lg text-violet-800 text-xl"
                >
                  {" "}
                  +{" "}
                </button>
              </div>
              <div className="h-0.5 bg-gradient-to-r from-indigo-200 to-indigo-200"></div>
              <h6 className="text-2xl font-semibold flex">
                {quantity * product?.price} birr{" "}
                <div className="mx-4 flex">
                  <p>{productLike?.likes?.length}</p>
                  {userInfo ? (
                    <Favorite productSLike={product} />
                  ) : (
                    <button
                      className="bg-[#E3E6E6] hover:bg-[#0397FF] duration-700 p-2 rounded-full"
                      onClick={() => {
                        setShowLogin(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              </h6>
              <div className="flex flex-row items-center gap-12">
                <div className="flex text-indigo-400 text-lg">
                  <h1>{userInfo?.firstName}</h1>
                  <h1 className="pl-4">{userInfo?.middleName}</h1>
                </div>
                <Pay amount={quantity * product?.price} />
              </div>
            </div>

            <div className="flex flex-col gap-2 lg:w-3/12">
              <div
                className="relative overflow-hidden bg-no-repeat bg-cover relative overflow-hidden bg-no-repeat bg-cover shadow-lg rounded-lg"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
              >
                <img
                  className="w-screen transition cursor-pointer duration-700"
                  src={web}
                  alt="news img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductModel isVisible={showModal} onClose={() => setShowModal(false)}>
        <Product_rate />
      </ProductModel>
      <ProductModel isVisible={showLogin} onClose={() => setShowLogin(false)}>
        <Loginform />
      </ProductModel>
    </Layout>
  );
};
export default Orders;
