import React, {useEffect, useState} from "react";
import address from '../../img/icon/discount1.png';
import image1 from '../../img/icon/bahirdar.jpg';
import stocklogo from '../../img/icon/stocklogo.png';

function Gallery() {

  return (
    <>
 <div className="bg-[#E3E6E6]">

    <div className="w-11/12 xl:w-11/12 mx-auto md:mt-12 xl:mt-6 mt-6">

      <div className="md:flex space-x-4">
      <div className="relative block group bg-white rounded-lg">
        <figure class="relative transition-all duration-300 cursor-pointer grayscale-0 hover:grayscale">
      
        <figcaption class="hidden group-hover:block absolute px-4 text-lg text-black top-12 pl-12">
        <div className="flex">

           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mt-2">
             <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
             <path stroke-linecap="round" stroke-linejoin="round" d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z" />
           </svg>
          <p className="pl-2">
            መንገዶችን እና አደባባዮችን ስያሜ መስጠት
          </p>
        </div>
        <div className="flex">

           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mt-2">
             <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
             <path stroke-linecap="round" stroke-linejoin="round" d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z" />
           </svg>
           <p className="pl-2">
            ትክክለኛ መረጃ መስጠት ቅድሚያ የሚጠቅመው እራስዎን ነው
            </p>
          </div>
          <div className="flex">

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mt-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z" />
            </svg>
           <p className="pl-2">
             ከሁሉም በፊት አድራሻዎን ያልሙ !
           </p>
           </div>
        <p className=" flex pt-4"> ዝርዝር መረጃ
             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
             </svg>
           </p>
        </figcaption>
       
        <a href="#" className="">
          <img class="rounded-lg w-screen h-48" src='https://address-backend.eplusapp.et/images/1687437759-product2.png?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80' alt="image description"/>
        </a>
        <figcaption class="group-hover:hidden absolute px-4 text-lg text-black bottom-6">
        <p>ይህ ኢጵላሣጵ ዲጂታል አድራሻ ልምት ነው... </p>
        </figcaption>
        </figure>
        <div className="flex">
          <h1 className="p-2 pl-6">ዲጂታል አድራሻ</h1>  
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
          </svg>

        </div>
      </div>

     <div className="relative group block bg-white rounded-lg">
        <figure class="relative transition-all duration-300 cursor-pointer filter grayscale-0 hover:grayscale">
        <figcaption class="hidden group-hover:block absolute px-4 text-lg text-white top-12 pl-12">
        <div className="flex">
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mt-2">
             <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
             <path stroke-linecap="round" stroke-linejoin="round" d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z" />
           </svg>
          <p className="pl-2">
            የማስታወቂያ ስራ</p>
            </div>
            <div className="flex">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mt-2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z" />
              </svg>
                <p className="pl-2">
                   መስራት እና ማሰራት የሚፈልጉትን መግለጽ
                </p>
            </div>
           <p className=" flex pt-4"> ዝርዝር መረጃ
             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
             </svg>
           </p>
         </figcaption>
        <a href="#">
          <img class="rounded-lg w-screen h-48" src={image1} alt="image description"/>
        </a>
        <figcaption class="group-hover:hidden absolute px-4 text-lg text-white bottom-6">
           <p>ማስተዋወቅ የሚፈልጉት ካለ ወይም ማስታወቂያዎችን ለማየት www.eplusapp.et</p>
        </figcaption>
        </figure>
        <div className="flex">
          <h1 className="p-2 pl-6">ዲጂታል ማስታዎቂያ</h1>  
          <img src={address} alt="address images" className="h-8 w-8 right-5"/>
        </div>
        
      </div> 
      </div>

      <div className="md:flex space-x-4 pt-6">
      <div className="relative group block bg-white rounded-lg">
        <figure class="relative transition-all duration-300 cursor-pointer grayscale-0 hover:grayscale">
      
        <figcaption class="hidden group-hover:block absolute px-4 text-lg text-white top-12 pl-12">
        <div className="flex">

           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mt-2">
             <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
             <path stroke-linecap="round" stroke-linejoin="round" d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z" />
           </svg>
          <p className="pl-2">
            አዳዲስ እቃዎች</p>
            </div>
            <div className="flex">

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mt-2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z" />
              </svg>
              <p className="pl-2"> የድርጅቶችን ምርት ብዛት እስከ ጥራት የጠበቀ </p>
            </div>
            <div className="flex">

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mt-2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z" />
             </svg>
             <p className="pl-2"> ሻጭ እና ገዢን ማገናኘት</p>
            </div>
           <p className=" flex pt-4"> ዝርዝር መረጃ
             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
             </svg>
           </p>
         </figcaption>
       
        <a href="#" className="">
          <img class="rounded-lg w-screen h-48" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/content-gallery-3.png" alt="image description"/>
        </a>
          <figcaption class="group-hover:hidden absolute px-4 text-lg text-white bottom-6">
            <p>መሸጥ እና መለወጥ የሚፈልጉት ካለ ኢጵላሣጵ የመገበያያ አውድን ይጠቀሙ</p>
          </figcaption>
        </figure>
        <div className="flex">
          <h1 className="p-2 pl-6">ዲጂታል መገበያያ</h1>  
          <img src={address} alt="address images" className="h-8 w-8 right-5"/>
        </div>
      </div>

     <div className="relative group block bg-white rounded-lg">
        <figure class="relative transition-all duration-300 cursor-pointer filter grayscale-0 hover:grayscale">
      
        <figcaption class="hidden group-hover:block absolute px-4 text-lg text-white top-12 pl-12">
        <div className="flex">

           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mt-2">
             <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
             <path stroke-linecap="round" stroke-linejoin="round" d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z" />
           </svg>
          <p className="pl-2">
            የአክሲዮን ባለቢት መሆን</p>
            </div>
            <div className="flex">

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mt-2">
                 <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                 <path stroke-linecap="round" stroke-linejoin="round" d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z" />
              </svg>
              <p className="pl-2">ፈጣን ልውውጥ </p>
            </div>
            <div className="flex">

           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mt-2">
             <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
             <path stroke-linecap="round" stroke-linejoin="round" d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z" />
           </svg>
           <p className="pl-2"> www.stock.eplusapp.et</p>
            </div>
           <p className=" flex pt-4"> ዝርዝር መረጃ
             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
             </svg>
           </p>
         </figcaption>
        <a href="#">
          <img class="rounded-lg w-screen h-48" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/content-gallery-3.png" alt="image description"/>
        </a>
        <figcaption class="group-hover:hidden absolute px-4 text-lg text-white bottom-6">
        <p>አክሲዮን መሸጥ እና መግዛት ይፈልጋሉ ?</p>
        </figcaption>
        </figure>
        <div className="flex">
          <h1 className="p-2 pl-6">አክሲዮን ገበያ</h1>  
          <img src={stocklogo} alt="address images" className="absolute h-12 w-16 pt-1 right-5"/>
        </div>
        
      </div> 
      </div>

    </div>

    </div>
    </>
  );
}

export default Gallery;