import { createSlice } from "@reduxjs/toolkit";
import { addProductRate, viewProductRate } from "../actions/productRateAction";

// initial state
const initialState = {
  loading: false,
  productRate: [],
  productRate: {},
  error: false,
  success: false,
  message: null,
};

// productRate slice
const productRateSlice = createSlice({
  name: "productRate",
  initialState,
  reducers: {},
  extraReducers: {
    [addProductRate.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [addProductRate.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.productRate = payload;
      state.success = true;
    },
    [addProductRate.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // get all products
    [viewProductRate.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [viewProductRate.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.singleProduct = payload;
      //console.log("singleProduct : ", payload);
    },
    [viewProductRate.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

// export
export default productRateSlice.reducer;
