import React from "react";
import { FaTelegram } from "react-icons/fa";
const Footers = () => {
  return (
    <footer className="bg-[#222222] text-center lg:text-left text-gray-400">
      <div className=" py-10 text-center md:text-left grid  w-11/12 xl:w-10/12 mx-auto mb-4">
        <div className="grid grid-1 md:grid-cols-1 lg:grid-cols-4 gap-0 flex md:justify-between">
          <div className="">
            <h3 className="text-white hover:text-primary text-lg mb-5 flex items-center justify-center md:justify-start ">
              <span className="decoration-4 decoration-[#0397FF] underline underline-offset-8 font-serif">
                Eplusa
              </span>
              pp
            </h3>
            <p className="font-display text-[#868686] hover:text-[#0397FF] text-sm flex items-center justify-center md:justify-start mb-4 motion-safe:hover:translate-x-2 duration-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 mr-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03
             4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953
             0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0
             0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                />
              </svg>
              <a href="https://address.eplusapp.et/">www.eplusapp.et</a>
            </p>

            <p className="font-display text-[#868686] text-sm flex font-serif items-center justify-center md:justify-start mb-4 motion-safe:hover:translate-x-2 duration-300 hover:text-[#0397FF]">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="home"
                className="w-4 mr-4"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path
                  fill="#868686"
                  d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
                ></path>
              </svg>
              Bahir Dar Kebele 14 floor 4 Room 413
            </p>
            <p className="font-display text-[#868686] hover:text-[#0397FF] font-serif text-sm flex items-center justify-center md:justify-start mb-4 motion-safe:hover:translate-x-2 duration-300 hover:text-[#0397FF]">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="phone"
                className="w-4 mr-4"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#868686"
                  d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
                ></path>
              </svg>
              +2519-1 848 4782
            </p>
            <p className="font-display text-[#868686] hover:text-[#0397FF] text-sm flex font-serif items-center justify-center md:justify-start mb-4 motion-safe:hover:translate-x-2 duration-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 mr-4"
              >
                <path
                  stroke-linecap="round"
                  d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
                />
              </svg>
              <a href="https://mail.google.com/">www.info88@gmail.com</a>
            </p>
          </div>

          <div>
            <h3 className="text-white hover:text-primary text-lg mb-5 flex items-center justify-center md:justify-start">
              <span className="decoration-4 decoration-[#0397FF] underline underline-offset-8 font-serif">
                Produ{" "}
              </span>
              cts
            </h3>
            <p className="mb-4  motion-safe:hover:translate-x-2 duration-300 font-display font-serif text-base text-[#868686] hover:text-[#0397FF]">
              <a href="https://address.eplusapp.et/">address</a>
            </p>
            <p className="mb-4 motion-safe:hover:translate-x-2 duration-300 font-display font-serif text-base text-[#868686] hover:text-[#0397FF]">
              <a href="https://eplusapp.et/">promotion website</a>
            </p>
            <p className="mb-4  motion-safe:hover:translate-x-2 duration-300 font-serif font-display text-base text-[#868686] hover:text-[#0397FF]">
              <a href="https://stock.eplusapp.et/">stock market</a>
            </p>
            <p className="mb-4 motion-safe:hover:translate-x-2 font-serif duration-300 font-display text-base text-[#868686] hover:text-[#0397FF]">
              <a href="/">ecommerce</a>
            </p>
          </div>
          <div className="">
          <h3 className="text-white hover:text-primary text-lg mb-5 flex items-center justify-center md:justify-start">
              <span className="decoration-4 decoration-[#0397FF] underline underline-offset-8 font-serif">
                Servi{" "}
              </span>
              ces
            </h3>
            <p className="mb-4 motion-safe:hover:translate-x-2 duration-300 font-display font-serif text-base text-[#868686] hover:text-[#0397FF]">
              <a href="#">Make everyone a shareholder</a>
            </p>
            <p className="mb-4 motion-safe:hover:translate-x-2 duration-300 font-display font-serif text-base text-[#868686] hover:text-[#0397FF]">
              <a href="#">Bus Reservation System</a>
            </p>
            <p className="mb-4 motion-safe:hover:translate-x-2 duration-300 font-display font-serif text-base text-[#868686] hover:text-[#0397FF]">
              <a href="#">Enrichment of technological solutions for institutions</a>
            </p>
          </div>

          <div className="md:mx-0 mx-5">
            <h3 class="text-white hover:text-primary text-lg mb-5 flex items-center justify-center md:justify-start">
              <span className=" mr-2 decoration-4 decoration-[#0397FF] underline underline-offset-8 font-serif">
                {" "}
                Follow
              </span>
              Me
            </h3>
            <p className="font-display text-white text-xs flex justify-between mb-6">
              <a
                href="https://www.facebook.com/profile.php?id=100087921900868"
                type="button"
                className="rounded-full border-2 border-white text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 hover:scale-110 hover:duration-300 w-8 h-8"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="facebook-f"
                  className="w-2 h-full mx-auto"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    fill="white"
                    d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                  ></path>
                </svg>
              </a>
              {/* <a
                href="#!"
                type="button"
                className="rounded-full border-2 border-white text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 hover:scale-110 hover:duration-300 w-9 h-9"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="twitter"
                  className="w-3 h-full mx-auto"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                  ></path>
                </svg>
              </a> */}
              <a href="https://t.me/eplutelegram">
                <FaTelegram className="w-9 h-9" />
              </a>
              <a
                href="#!"
                type="button"
                className="rounded-full border-2 border-white text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 hover:scale-110 hover:duration-300 w-8 h-8"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="instagram"
                  className="w-3 h-full mx-auto"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="white"
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                  ></path>
                </svg>
              </a>
              <a
                href="#!"
                type="button"
                className="rounded-full border-2 border-white text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 hover:scale-110 hover:duration-300 w-8 h-8"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="linkedin-in"
                  className="w-3 h-full mx-auto"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="white"
                    d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                  ></path>
                </svg>
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="text-center p-6 text-white bg-[#0E0E0E] font-serif">
        <span>
        Copyright © {new Date().getFullYear()} Eplusapp. All rights reserved.
        </span>
      </div>
    </footer>
  );
};
export default Footers;
