import React from "react";
import stocklogo from '../../../img/icon/stocklogo.png';
import discount from '../../../img/icon/discount1.png';
import { FcShipped } from "react-icons/fc";
import { FcCollaboration } from "react-icons/fc";
import { FcShop } from "react-icons/fc";
const Service = () => {

    return(
   <>  
    <div className="hidden md:flex relative m-auto w-11/12">
      <div>
         <div className=" grid grid-cols-5 divide-x-2 divide-slate-400 p-4 cursor-pointer border-2 border-slate-400">
         <div className=" text-sm flex pl-2">
              <FcShipped className="h-12 w-12"/>
            <div className=" pl-2">
            <a href="#" className="font-bold hover:text-[#0397FF]">ነጻ አገልግሎት </a>
            <button>ለሁሉም ትዕዛዝ ከነጻ አቅርቦት ጋ</button>
            </div>
         </div>
         <div className="text-sm flex pl-2">
         {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
             <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
            </svg> */}
            <FcCollaboration className="w-12 h-12"/>
           <div className="pl-2">
            <a href="#" className="font-bold hover:text-[#0397FF]">የመስመር ላይ ድጋፍ 24/7</a>
            <button>እንዲሁም በቴክኒክ ድጋፍ 24/7</button>
           </div>
         </div>
         <div className="text-sm flex">
            <img src={stocklogo} className="w-12 h-12"/>
            <div className="">
            <a href="https://stock.eplusapp.et/" className="font-bold hover:text-[#0397FF] pl-4">ኢጵላሣጵ አክሲዮን</a>
            <button>ገንዘብዎን በተገቢው ቦታ ያስቀምጡ</button>
          </div>
         </div>
         <div className="text-sm flex pl-2">
           <img src={discount} className="h-12 w-12" />
          <div className="pl-2">
           <a href="#" className="font-bold hover:text-[#0397FF]">ለአክሲዮን ባለቤቶች በቅናሽ</a>
           <button>ለሁሉም ተጠቃሚ በሁሉም ምርቶች ላይ እስከ 50% ቅናሽ</button>
           </div>
         </div>
         <div className="text-sm flex pl-2">
          {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
           <path stroke-linecap="round" stroke-linejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
          </svg> */}
            <FcShop className="w-12 h-12"/>
           <div className="pl-2">
             <a href="https://address.eplusapp.et/" className="font-bold hover:text-[#0397FF]">ኢጵላሣጵ አድራሻ</a>
             <button> ሁሉንም መረጃ በአግባቡ ምዝግብ </button>
            </div>
         </div>
        </div>
         </div>
        </div>
        </>
    )
}
export default Service;