import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProductCategory } from "../../../actions/productCategory";
import Loading from "../loading";
import AddressBaseUrl from "../../../utils/BaseUrl";
import { FaStar } from "react-icons/fa";
import { viewProductRate } from "../../../actions/productRateAction";
import { viewProducts } from "../../../actions/productAction";
import { useForm } from "react-hook-form";
import { BsCart4 } from "react-icons/bs";
import { addToCart } from "../../../actions/cartAction";
import Favorite from "./favorite";
import ProductModel from "./popup-model";
import Loginform from "../../users/login";
import { dataProducts } from "../data";

const AllCategory = ({ productDetail }) => {
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLogin, setShowLogin] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const { productCategory } = useSelector((state) => state.productCategory);

  useEffect(() => {
    dispatch(ProductCategory());
  }, []);

  //navigate to product orders page
  const handleGetOrder = (id) => {
    navigate("/product/orders/" + id);
  };

  // navigate to product like pages
  const handleGetLike = (id) => {
    navigate("/product/like/" + id);
  };

  const { productRate } = useSelector((state) => state.productRate);

  const { product } = useSelector((state) => state.product);
  // console.log("all products are1 ", product);
  const [data, setData] = useState(dataProducts || "");

  useEffect(() => {
    dispatch(viewProducts());
  }, []);
  //change useselector data to useState states with react js
  useEffect(() => {
    setData(dataProducts);
  }, [dataProducts]);

  useEffect(() => {
    dispatch(viewProductRate());
  }, []);

  const filterResult = (catData) => {
    const result = dataProducts.filter((curData) => {
      return curData.categoryName === catData;
    });
    setData(result);
  };

  const rateIndex = productRate.length;

  let Total = 0;
  let newData1 = Array.prototype.map.call(productRate, (item) => {
    Total = +Total + +item.rating;
  });
  const totalRating = Total / rateIndex;
  console.log("rate : ", totalRating);

  const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9",
  };

  const styles = {
    stars: {
      display: "flex",
      flexDirection: "row",
    },
  };
  const stars = Array(5).fill(0);

  const [productData, setProductData] = useState("");
  const handeleToDetail = (values) => {
    console.log("all catagory : ", values);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    setProductData(values);
  };
  const addToCartHandler = (data) => {
    const newData = { ...data, quantity: 1 };
    dispatch(addToCart(newData));
  };
  return (
    <>
      <div className="bg-[#E3E6E6]">
        <div className="w-11/12 xl:w-11/12 mx-auto pb-6">
          <section className="mb-6 text-gray-800 text-center ">
            <div
              style={{}}
              className=" md:flex flex-wrap justify-between items-center mx-auto md:px-6 lg:px-6 px-1"
            >
              <div className="hidden md:flex items-center py-3">
                <button className="mr-6 text-lg font-display text-[#0397FF] font-medium hover:text-black">
                  E+up
                </button>
              </div>
              <div className="flex items-center md:mr-7 lg:mr-7 md:space-x-4 lg:space-x-4 space-x-2 pb-2 md:pb-0 lg:pb-0">
                <button
                  className="text-black font-medium hover:underline text-sm"
                  onClick={() => setData(dataProducts)}
                >
                  All
                </button>

                <button
                  className="text-black font-medium hover:underline text-sm"
                  onClick={() => filterResult("cloth")}
                >
                  Cloth
                </button>
                <button
                  className="text-black font-medium hover:underline text-sm"
                  onClick={() => filterResult("electronics")}
                >
                  Electronics
                </button>
                <button
                  className="text-black font-medium hover:underline text-sm"
                  onClick={() => filterResult("machinary")}
                >
                  Machinery
                </button>
                <button
                  className="text-black font-medium hover:underline text-sm"
                  onClick={() => filterResult("softawre")}
                >
                  Software
                </button>
              </div>
            </div>

            <div class="grid lg:grid-cols-4 xl:gap-5 md:gap-6 xl:gap-x-4">
              {data?.length > 0 ? (
                data?.slice(0, 15).map((values, index) => {
                  // const {id ,featureImage, name, description, price } =values;
                  return (
                    <>
                      <div key={index} className="mb-6 lg:mb-0">
                        <div className="relative group block bg-white overflow-hidden">
                          <div className="flex">
                            <div
                              className="p-2 relative overflow-hidden bg-no-repeat bg-cover relative overflow-hidden bg-no-repeat bg-cover rounded-lg"
                              data-mdb-ripple="true"
                              data-mdb-ripple-color="light"
                            >
                              <img
                                onClick={() => handleGetOrder(values.id)}
                                className="w-screen h-72 transition cursor-pointer duration-700"
                                // src={`${AddressBaseUrl}/images/${values.featureImage}`}
                                src={values?.linkImg}
                                alt="product img not found"
                                // onClick={() => handeleToDetail(values)}
                              />
                            </div>
                          </div>

                          <div className=" absolute bottom-[20%] -translate-y-0 -left-[13%] group-hover:left-[35%] text-sm rounded-sm cursor-pointer text-black rounded-tr-xl  opacity-70 transition-[1s] duration-1000">
                            <div className="relative">
                              <div className="pt-1">
                                <button
                                  className="group block bg-[#E3E6E6] hover:bg-[#0397FF] duration-1000 p-2 rounded-full hover:text-white text-black hover:rotate-180"
                                  onClick={() => handleGetOrder(values.id)}
                                  data-te-toggle="tooltip"
                                  data-te-placement="top"
                                  // data-te-ripple-init
                                  data-te-ripple-color="light"
                                  title="add"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5 hover:text-white text-black"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                    />
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="group-hover:bottom-[20%] absolute -bottom-[10%] -translate-x-0 left-[50%] text-sm rounded-sm cursor-pointer text-black rounded-tr-xl  opacity-70 transition-[1s] duration-1000">
                            <div className="relative">
                              <div className="pt-1">
                                {userInfo ? (
                                  <Favorite productSLike={values} />
                                ) : (
                                  <button
                                    className="bg-[#E3E6E6] hover:bg-[#0397FF] duration-700 p-2 rounded-full hover:rotate-45 rotate-duration-1000"
                                    onClick={() => {
                                      setShowLogin(true);
                                    }}
                                    data-te-toggle="tooltip"
                                    data-te-placement="top"
                                    data-te-ripple-color="light"
                                    title="like"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="w-5 h-5"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                                      />
                                    </svg>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className=" group-hover:left-[65%] absolute bottom-[20%] -translate-x-0  left-[100%] text-sm rounded-sm cursor-pointer text-black rounded-tr-xl  opacity-70 transition-[1s] duration-1000">
                            <div className="relative">
                              <div className="pt-1">
                                <button
                                  className="bg-[#E3E6E6] hover:bg-[#0397FF] duration-700 p-2 rounded-full hover:rotate-90 rotate-duration-1000"
                                  type="button"
                                  onClick={() => addToCartHandler(values)}
                                  data-te-toggle="tooltip"
                                  data-te-placement="top"
                                  // data-te-ripple-init
                                  data-te-ripple-color="light"
                                  title="add to cart"
                                >
                                  <BsCart4 className="w-5 h-5 hover:text-white text-black" />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="p-2">
                            <p className="text-sm font-bold  mt-4 text-center">
                              {values.name.substring(0, 24)}
                            </p>

                            <div
                              style={styles.stars}
                              className="m-auto justify-center"
                            >
                              {stars.map((_, index) => {
                                return (
                                  <FaStar
                                    key={index}
                                    size={15}
                                    color={
                                      3 > index ? colors.orange : colors.grey
                                    }
                                    style={{
                                      marginRight: 5,
                                      cursor: "pointer",
                                    }}
                                    {...register(`rating`)}
                                    values={3}
                                  />
                                );
                              })}
                              <h1 className="text-sm">rate</h1>
                            </div>
                            <p className="text-lg font-bold">
                              {values.price} birr
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <>
                  <div>
                    <Loading />
                  </div>
                </>
              )}
            </div>
          </section>
        </div>
      </div>
      <ProductModel isVisible={showLogin} onClose={() => setShowLogin(false)}>
        <Loginform />
      </ProductModel>
    </>
  );
};
export default AllCategory;
