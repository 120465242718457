import { createSlice } from '@reduxjs/toolkit'
import { ProductOrder } from '../actions/productOrderAction';

// initial state
const initialState = {
  loading: false,
  products:[],
  product: {},
  error: false,
  success: false,
  message: null,
}

// productOrder slice
const productOrderSlice = createSlice({
  name: 'productOrder',
  initialState,
  reducers: {},
  extraReducers: {

    // add product orders
    [ProductOrder.pending]: (state) => {
      state.loading = true
      state.error = false
    },
    [ProductOrder.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true 
      state.product = payload
    },
    [ProductOrder.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },

  },

})

// export
export default productOrderSlice.reducer