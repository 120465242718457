import { createSlice } from '@reduxjs/toolkit'
import { viewManufacture } from '../actions/manufactureAction';

// initial state
const initialState = {
  loading: false,
  manufactures:[],
  manufacture: {},
  error: false,
  success: false,
  message: null,
}

// manufacture slice
const manufactureSlice = createSlice({
  name: 'manufacture',
  initialState,
  reducers: {},
  extraReducers: {
    // view users
    [viewManufacture.pending]: (state) => {
      state.loading = true
      state.error = false
    },
    [viewManufacture.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true 
      state.manufacture = payload
    },
    [viewManufacture.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },

})

// export
export default manufactureSlice.reducer